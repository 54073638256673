import React, { useState } from "react";

const formsData = [
  { id: "basic-info", title: "Basic Information" },
  { id: "payment", title: "Payment Details" },
  { id: "Identification", title: "Identifications" },
  { id: "emergency-contacts", title: "Emergency Contacts" },
  { id: "history", title: "Medical History" },
  { id: "allergy-meds", title: "Allergies & Medications" },
  { id: "measures-vitals", title: "Measures & Vitals" },
  { id: "consents", title: "Consent Forms" },
  { id: "upload-docs", title: "Document Upload" },
];

const RequiredForms = ({onClose}) => {
  const [activeForm, setActiveForm] = useState(null);
  const [completedForms, setCompletedForms] = useState([]);
  const [allFormsSubmitted, setAllFormsSubmitted] = useState(false);

  const handleFormClick = (formId) => {
    setActiveForm(formId);
  };

  const handleCompleteForm = () => {
    if (activeForm && !completedForms.includes(activeForm)) {
      setCompletedForms([...completedForms, activeForm]);
    }
    setActiveForm(null);
  };

  const handleSubmitIntakeForms = () => {
    setAllFormsSubmitted(true);
  };

  return (
    <div className="modal_RequiredForms">
      
      <div className="main-content">
      <div
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    width: 50,
                    position: "absolute",
                    right: 18,
                    top: 50,
                  }}
                  onClick={onClose}
                >
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                    style={{ height: 20, width: 20 }}
                  />
                </div>
        <div className="sidebar_RequiredForms">
          <h3>Required Forms</h3>
          {formsData.map((form) => (
            <div
              key={form.id}
              className={`form-card_RequiredForms ${
                activeForm === form.id ? "active" : ""
              } ${completedForms.includes(form.id) ? "completed" : ""}`}
              onClick={() => handleFormClick(form.id)}
            >
              <h3>
                {form.title}
                {completedForms.includes(form.id) && (
                  <span className="checkmark">✓</span>
                )}
              </h3>
            </div>
          ))}
        </div>

        <div className="form-content">
          {!activeForm && !allFormsSubmitted && (
            <div className="welcome-message">
              <h2>Welcome to EnnHealth</h2>
              <p className="welcome-text">
                Please complete your patient forms for your visit with NAGELEY
                MICHEL DNP at EnnHealth - Telehealth
                <br /> on Wednesday, January 29 at 7:00PM EST.
                <br />
                <br /> You'll save time in the waiting room and have a smoother
                visit.
              </p>
            </div>
          )}

          {activeForm && (
            <div id="formDetails">
              <h2>{formsData.find((form) => form.id === activeForm)?.title} Form</h2>
              <p className="form-description">
                Please complete the {activeForm.replace(/-/g, " ")} form. All
                fields marked with * are required.
              </p>
              <button className="cta-button" onClick={handleCompleteForm}>
                Save
              </button>
            </div>
          )}

          {!activeForm && completedForms.length === formsData.length && !allFormsSubmitted && (
            <button className="cta-button" onClick={handleSubmitIntakeForms}>
              Submit Intake Forms
            </button>
          )}

          {allFormsSubmitted && (
            <>
            <div className="success-message">
              <h2>Thank You!</h2>
              <p>Your intake forms have been submitted successfully.</p>
              <p>
                Your appointment with <strong>NAGELEY MICHEL DNP</strong> is
                scheduled for:
              </p>
              <p>
                <strong>Wednesday, January 29 at 7:00 PM EST</strong>
              </p>
              <p>We look forward to seeing you!</p>
            </div>
             <button className="cta-button" onClick={onClose}>
            Close
           </button>
           </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequiredForms;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  AddpatientVitals,
  DeletepatientVitals,
  GetpatientVitals,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  BloodPressure: Yup.string()
    .matches(/^\d{2,3}\/\d{2,3}$/, "Invalid format (e.g., 120/80)")
    .required("Blood Pressure is required"),
  HeartRate: Yup.number()
    .typeError("HeartRate Must be a required")
    .positive("Must be positive")
    .required("Heart Rate is required"),
  RespiratoryRate: Yup.number()
    .typeError("RespiratoryRate Must be a required")
    .positive("Must be positive")
    .required("Respiratory Rate is required"),
  Temperature: Yup.number()
    .typeError("Temperature Must be a required")
    .required("Temperature is required"),
  Sp02: Yup.number()
    .typeError("Sp02 be a required")
    .min(0)
    .max(100)
    .required("SpO2 is required"),
  Weight: Yup.number()
    .typeError("Must be a number")
    .positive("Must be positive")
    .required("Weight is required"),
  Height: Yup.number()
    .typeError("Height be a required")
    .positive("Must be positive")
    .required("Height is required"),
  BMI: Yup.number()
    .typeError("BMI be a required")
    .positive("Must be positive")
    .required("BMI is required"),
  PeakFlow: Yup.number()
    .typeError("PeakFlow be a required")
    .positive("Must be positive")
    .required("Peak Flow is required"),
  WaistCircumference: Yup.number()
    .typeError("WaistCircumference be a required")
    .positive("Must be positive")
    .required("Waist Circumference is required"),
  HeadCircumference: Yup.number()
    .typeError("HeadCircumference be a required")
    .positive("Must be positive")
    .required("Head Circumference is required"),
  Memo: Yup.string().max(300, "Max 300 characters"),
  RecordedOnDate: Yup.date().required("Recorded date is required"),
});

const Vitals = ({ info }) => {
  const [ismodal, setIsModal] = useState(false);
  const [data, setdata] = useState([]);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const CloseModal = () => {
    reset();
    setIsModal(false);
  };
  const onSubmit = async (data) => {
    const bloodPressureValues = data.BloodPressure.split("/").map(Number);

    const payload = {
      ...data,
      BloodPressure: {
        systolic: bloodPressureValues[0] || 0,
        diastolic: bloodPressureValues[1] || 0,
      },
      RecordedOnDate: new Date(data.RecordedOnDate).toISOString().split("T")[0],

      agencyIds: profileData?._id || "",
      patientId: info?.patientId?._id || "",
    };

    console.log("Transformed Data:", payload);
    try {
      const respo = await AddpatientVitals(payload);
      ShowToast(respo?.data?.message);
      Getdata();
      CloseModal();
    } catch (error) {
      console.log(error);
      CloseModal();
    }
  };
  const Getdata = async () => {
    try {
      const respo = await GetpatientVitals(info?.patientId?._id);
      // console.log(respo?.data?.data?.data, "respo======");
      setdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getdata();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Problem?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await DeletepatientVitals(id);
      ShowToast(respo?.data?.message);
      Getdata();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container_NotesTabScreen">
      <h1>Vitals</h1>
      <button
        className="add-button_NotesTabScreen"
        style={{ float: "right" }}
        onClick={() => {
          setIsModal(true);
        }}
      >
        Add Vitals
      </button>
      <div
        className="tabs-container__three"
        style={{ display: "flex", width: "100%", justifyContent: "left" }}
      />

      {ismodal && (
        <div className="modal_PatientDocuments">
          <h2>Add Vitals</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row_Vitals">
              <div className="form-group_Vitals">
                <label htmlFor="BloodPressure">Blood Pressure (mmHg)</label>
                <input
                  type="text"
                  id="BloodPressure"
                  {...register("BloodPressure")}
                  placeholder="e.g., 120/80"
                />
                <p className="error">{errors.BloodPressure?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="HeartRate">Heart Rate (bpm)</label>
                <input
                  type="number"
                  id="HeartRate"
                  {...register("HeartRate")}
                  placeholder="Enter heart rate"
                />
                <p className="error">{errors.HeartRate?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="RespiratoryRate">Respiratory Rate (bpm)</label>
                <input
                  type="number"
                  id="RespiratoryRate"
                  {...register("RespiratoryRate")}
                  placeholder="Enter respiratory rate"
                />
                <p className="error">{errors.RespiratoryRate?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="Temperature">Temperature (°F)</label>
                <input
                  type="number"
                  id="Temperature"
                  step="0.1"
                  {...register("Temperature")}
                  placeholder="Enter temperature"
                />
                <p className="error">{errors.Temperature?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="Sp02">SpO2 (%)</label>
                <input
                  type="number"
                  id="Sp02"
                  step="0.1"
                  {...register("Sp02")}
                  placeholder="Enter SpO2 level"
                />
                <p className="error">{errors.Sp02?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="weight">Weight (kg)</label>
                <input
                  type="number"
                  id="weight"
                  step="0.1"
                  {...register("Weight")}
                  placeholder="Enter weight"
                />
                <p className="error">{errors.Weight?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="Height">Height (cm)</label>
                <input
                  type="number"
                  id="Height"
                  step="0.1"
                  {...register("Height")}
                  placeholder="Enter Height"
                />
                <p className="error">{errors.Height?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="BMI">BMI</label>
                <input
                  type="number"
                  id="BMI"
                  step="0.1"
                  {...register("BMI")}
                  placeholder="Enter BMI"
                />
                <p className="error">{errors.BMI?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="PeakFlow">Peak Flow (L/min)</label>
                <input
                  type="number"
                  id="PeakFlow"
                  step="0.1"
                  {...register("PeakFlow")}
                  placeholder="Enter peak flow"
                />
                <p className="error">{errors.PeakFlow?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="WaistCircumference">
                  Waist Circumference (cm)
                </label>
                <input
                  type="number"
                  id="WaistCircumference"
                  step="0.1"
                  {...register("WaistCircumference")}
                  placeholder="Enter WaistCircumference circumference"
                />
                <p className="error">{errors.WaistCircumference?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="HeadCircumference">
                  Head Circumference (cm)
                </label>
                <input
                  type="number"
                  id="HeadCircumference"
                  step="0.1"
                  {...register("HeadCircumference")}
                  placeholder="Enter HeadCircumference circumference"
                />
                <p className="error">{errors.HeadCircumference?.message}</p>
              </div>

              <div className="form-group_Vitals">
                <label htmlFor="Memo">Memo</label>
                <textarea
                  id="Memo"
                  {...register("Memo")}
                  placeholder="Enter notes"
                  rows={2}
                />
                <p className="error">{errors.Memo?.message}</p>
              </div>
            </div>

            <div className="form-group_Vitals">
              <label htmlFor="RecordedOnDate">Recorded On</label>
              <input
                type="date"
                id="RecordedOnDate"
                {...register("RecordedOnDate")}
              />
              <p className="error">{errors.RecordedOnDate?.message}</p>
            </div>

            <div className="modal-footer_Vitals">
              <button
                type="button"
                className="cancel-button_Vitals"
                onClick={CloseModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-button_Vitals"
                style={{ marginLeft: 10 }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
      <div className="vitals-list_Vitals">
        <table>
          <thead>
            <tr>
              <th>Recorded Date</th>
              <th>BP</th>
              <th>HR</th>
              <th>RR</th>
              <th>Temp</th>
              <th>SpO2</th>
              <th>Weight</th>
              <th>Height</th>
              <th>BMI</th>
              <th>Peak Flow</th>
              <th>Waist Circumference</th>
              <th>Head Circumference</th>
              <th>Memo</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="10">No data found</td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={item._id || index}>
                  <td>{new Date(item.RecordedOnDate).toLocaleDateString()}</td>
                  <td>
                    {item.BloodPressure.systolic +
                      " / " +
                      item.BloodPressure.diastolic}
                  </td>
                  <td>{item.HeartRate}</td>

                  <td>{item.RespiratoryRate}</td>
                  <td>{item.Temperature}</td>
                  <td>{item.Sp02}</td>
                  <td>{item.Weight}</td>
                  <td>{item.Height}</td>
                  <td>{item.BMI}</td>
                  <td>{item.PeakFlow}</td>
                  <td>{item.WaistCircumference}</td>
                  <td>{item.HeadCircumference}</td>
                  <td>
                    <button
                      className="btn"
                      style={{ backgroundColor: 'red',color:'white' }}
                      onClick={() => handleRemove(item._id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Vitals;

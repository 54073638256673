import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { AddpatientMedication, DeletepatientMedication, GetpatientMedication } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  Medication: Yup.string().required("Medication is required"),
  Code: Yup.string()
    .required("Code is required")
    .matches(/^[A-Za-z0-9]+$/, "Code must be alphanumeric"),
  StartDate: Yup.date().required("Start Date is required"),
  EndDate: Yup.date()
    .nullable()
    .min(Yup.ref("StartDate"), "End Date cannot be before Start Date"),
  Action: Yup.string().required("Action is required"),
  Dose: Yup.string().required("Dose is required"),
  Unit: Yup.string().required("Unit is required"),
  Route: Yup.string().required("Route is required"),
  Frequency: Yup.string().required("Frequency is required"),
  Directions: Yup.string().required("Status is required"),
  Instructions: Yup.string()
    .required("Additional Instructions is required")
    .max(300, "Instructions must be under 300 characters"),
});
const Medicationsagency = ({info}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [ismodal, setIsModal] = useState(false);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
const [medicationdata, setmedicationdata] = useState([])
  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    setIsModal(false);
    reset();
  };
const onSubmit = async (data) => {
    const payload = {
      ...data,
      patientId: info?.patientId?._id,
      agencyId: profileData?._id,
    };
    // console.log(payload, "data====");
    // return;
    try {
      const respo = await AddpatientMedication(payload);
      // console.log(respo?.data?.data,"resp======");
      ShowToast(respo?.data?.message);
      Getdata();
      CloseModal();
    } catch (error) {
      console.log(error);
    }
  };
  const Getdata = async () => {
    try {
      const respo = await GetpatientMedication(info?.patientId?._id);
      setmedicationdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getdata();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Medication?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await DeletepatientMedication(id);
      ShowToast(respo?.data?.message);
      Getdata();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>Medications</h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add Medication
        </button>
        <div
          className="tabs-container__three"
          style={{ display: "flex", width: "100%", justifyContent: "left" }}
        ></div>
        {ismodal && (
          <div className="modal_PatientDocuments" id="addMedicationModal">
            <div
              className="close-icon"
              onClick={CloseModal}
              style={{
                position: "absolute",
                top: "0px",
                right: "5px",
                cursor: "pointer",
                fontSize: "20px",
                color: "red",
                fontWeight: "bold",
              }}
            >
              X
            </div>
            <div className="eprescribe-status_Medications">
              Ensure secure delivery of prescriptions—enroll in ePrescribe
              today!{" "}
              <a
                style={{
                  color: "#0056b3",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                ENROLL NOW
              </a>
            </div>
            <h2>Add Medication</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row_Medications">
                <div className="form-group_Medications">
                  <label htmlFor="Medication">Medication</label>
                  <input
                    type="text"
                    id="Medication"
                    placeholder="Search or enter Medication name"
                    list="medicationList"
                    {...register("Medication")}
                  />
                  <datalist id="medicationList">
                    <option value="Aspirin"></option>
                    <option value="Paracetamol"></option>
                    <option value="Ibuprofen"></option>
                    <option value="Metformin"></option>
                    <option value="Atorvastatin"></option>
                  </datalist>
                  <div className="error">{errors.Medication?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Code">Code</label>
                  <input
                    type="text"
                    id="Code"
                    placeholder="Enter Code"
                    {...register("Code")}
                  />
                  <div className="error">{errors.Code?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="StartDate">Start Date</label>
                  <input
                    type="date"
                    id="StartDate"
                    {...register("StartDate")}
                  />
                  <div className="error">{errors.StartDate?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="EndDate">End Date</label>
                  <input type="date" id="EndDate" {...register("EndDate")} />
                  <div className="error">{errors.EndDate?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Action">Action</label>
                  <select id="Action" {...register("Action")}>
                    <option value="">Select Action</option>
                    <option value="Start">Start</option>
                    <option value="Stop">Stop</option>
                  </select>
                  <div className="error">{errors.Action?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Dose">Dose</label>
                  <input
                    type="text"
                    id="Dose"
                    placeholder="Enter Dose"
                    {...register("Dose")}
                  />
                  <div className="error">{errors.Dose?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Unit">Unit</label>
                  <select id="Unit" {...register("Unit")}>
                    <option value="">Select Unit</option>
                    <option value="mg">mg</option>
                    <option value="ml">ml</option>
                  </select>
                  <div className="error">{errors.Unit?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Route">Route</label>
                  <select id="Route" {...register("Route")}>
                    <option value="">Select Route</option>
                    <option value="Oral">Oral</option>
                    <option value="IV">IV</option>
                  </select>
                  <div className="error">{errors.Route?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Frequency">Frequency</label>
                  <select id="Frequency" {...register("Frequency")}>
                    <option value="">Select Frequency</option>
                    <option value="Once daily">Once daily</option>
                    <option value="Twice daily">Twice daily</option>
                  </select>
                  <div className="error">{errors.Frequency?.message}</div>
                </div>

                <div className="form-group_Medications">
                  <label htmlFor="Directions">Status</label>
                  <select id="Directions" {...register("Directions")}>
                    <option value="">Select Directions</option>
                    <option value="Active">Active</option>
                    <option value="Discontinued">Discontinued</option>
                    <option value="Not Administered">Not Administered</option>
                  </select>
                  <div className="error">{errors.Directions?.message}</div>
                </div>

                <div
                  className="form-group_Medications"
                  style={{ flex: "1 1 100%" }}
                >
                  <label htmlFor="Instructions">Additional Instructions</label>
                  <textarea
                    id="Instructions"
                    placeholder="Enter additional Instructions"
                    rows={2}
                    {...register("Instructions")}
                  />
                  <div className="error">{errors.Instructions?.message}</div>
                </div>
              </div>

              <div className="modal-footer_Medications">
                <button
                  type="button"
                  className="cancel-button_Medications"
                  onClick={CloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button_Medications"
                  style={{ marginLeft: 10 }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="notes_style">
          {/* <h2>Medication List</h2> */}
          <table>
            <thead>
              <tr>
                <th>Medication</th>
                <th>Code</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
                <th>Dose</th>
                <th>Unit</th>
                <th>Route</th>
                <th>Frequency</th>
                <th>Status</th>
                <th>Instructions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {medicationdata.length === 0 ? (
                <tr>
                  <td colSpan="10">No data found</td>
                </tr>
              ) : (
                medicationdata.map((item, index) => (
                  <tr key={item._id || index}>
                    <td>{item.Medication}</td>
                    <td>{item.Code}</td>
                    <td>{new Date(item.StartDate).toLocaleDateString()}</td>
                    <td>
                      {new Date(item.EndDate).toLocaleDateString()}
                    </td>
                    <td>{item.Action}</td>
                    <td>{item.Dose}</td>
                    <td>{item.Unit}</td>
                    
                    <td>{item.Route}</td>
                    <td>{item.Frequency}</td>
                    <td>{item.Directions}</td>
                    <td>{item.Instructions}</td>
                    <td>
                      <button
                        className="btn"
                        style={{ backgroundColor: 'red',color:'white' }}
                        onClick={() => handleRemove(item._id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Medicationsagency;


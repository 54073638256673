export const BASE_URL = "https://api.ennhealth.app/api/";
export const IMAGE_BASE_URL = "https://api.ennhealth.app/";
export const BASE_URL_TWO ="https://rs8s65nn-5050.inc1.devtunnels.ms";
export const POST_METHOD = "POST";
export const GET_METHOD = "GET";
export const PUTMETHOD = "PUT";
export const PATCHMETHOD = "PATCH";
export const DELETE = "DELETE";
export const REGISTER = "auth/user/register";
export const UPLOAD_IMAGE ="fileUpload"
export const SLOT = "slots";
export const AVAILABLE_DAYS = "availableDays";
export const CREATE_AVAILABLE_DAYS = "availableDays/createAvailableDays";



// agency flow 
export const AGENCY_LOGIN ='agency/login'
export const AGENCY_SIGNUP="agency/signUp"
export const Agency_CHANGE_PASSWORD="agency/changePassword"
export const CLINIC_UPDATE="agency/updateUser"
export const CLINIC_PROFILE_GET="agency/getProfile"
export const DOCTOR_REQUETS="doctor/ClinicList"
export const PATIENT_LIST="doctor/getAllPatientRequest?"
export const GET_AGENCY_REQUEST_LIST="doctor/getAgencyRequestList"
export const FORGET = "forget"
export const RESET_PASSWORD ="RestePassword"
export const PATIENT_UPDATE_REQUEST ='patient/patientRequestUpdate'
export const AGENCY_SIDE_PATIENT_EMERGENCYCONTANT ='patient/patientEmergencyContacts'
export const DELETE_IDENTIFICATION ='patient/patientRequestDeleteIdentification'
export const REMOVE_PATIENT_CARD ='patient/removePatientCard'
export const ADD_PATIENT_CARD ='patient/addPatientCard'
export const REMOVE_PATIENT_CARD_AGENCY ='patient/removePatientRequestCard'
export const ADD_PATIENT_CARDD_AGENCY ='patient/addPatientRequestCard'
export const INSURANCEDETAILS ='insurence/details'
export const AUTHORIZATIONS='insurence/authorization'
export const FAMILYHISTORY='history/familyHistory'
export const MEDICALHISTORY='history/medicalHistory'
export const PSYCHIATRICHISTORY='history/psychiatricHistory'
export const LIFESTYLEDETERMINANTS ="history/lifestyleDeterminants"
export const SURGICALHISTORY='history/surgicalHistory'
export const TEAMMEMBERS="agency/getAgencyDoctor?id="
export const FACILITYTEAMMEMEBER="agency/faciliteTeamMemberList"
export const SERVICECODE="serviceCode"
export const SERVICELOCATION="ServiceLocation"
export const GET_PROVIDER_TYPE="providerType"
export const PARACTICE="agency/faciliteTeamList"
export const GET_SERVICE_BOOKING='agency/BookingServices'
export const GET_AGENCY_BOOKING='agency/TreatmentTypes'
export const GET_CREDENTIALS='agency/Credentials'
export const PROVIDERREQUIREMENT='agency/providerRequirement'
export const CLIENTREQUIREMENT='agency/ClientRequirement'
export const GET_CONDITION_TREATED='agency/ConditionTreated'
export const GET_SPECIALTUES='agency/Specialties'
export const GET_DELIVERY_MODE='agency/DeliveryMode'
export const GET_PAYMENT_SOURCES='agency/PaymentSources'
export const MODALITIES='Modalities'
export const LANGUAGES='Languages'
export const INSURANCE='Insurance'
export const SUBSCRIPTION='subscriptions'
export const AGENCY_PLAN_MANAGEMENT ='agency/AgencyPlanManagement'
export const ADMIN_INSURENCE = "adminInsurence"
export const CPTCODE ='cptCode'
export const NOTES='notes'
export const MEASURE='notes/measure'
export const ADD_ALLERGY='doctor/AddAllergy'
export const GET_ALLERGY='doctor/getAllergyList?patientId='
export const DELETE_ALLERGY='doctor/deleteAllergy?id='
export const ADD_MEDICATION='doctor/addMedication'
export const GET_MEDICATION='doctor/getMedicationList?patientId='
export const DELETE_MEDICATION='doctor/deleteMedication?id='
export const ADD_PATIENTDOCUMENT='patient/patientDocument'

export const ADD_VITALS='doctor/AddVitals'
export const GET_VITALS='doctor/getVitalsList?patientId='
export const DELETE_VITALS='doctor/deleteVitals?id='
export const ADD_LABORDER='doctor/LabOrder'

export const ADD_CONSENTMANAGMENT='agency/consentManagement'

// doctor flow 
export const DOCTOR_LOGIN ='doctor/login'
export const DOCTOR_SIGNUP="doctor/signUp"
export const GET_CATEGORY ="category"
export const DOCTOR_CHANGE_PASSWORD="doctor/changePassword"
export const GET_DOCTOR_PROFILE = "doctor/getProfile"
export const UPDATE_DOCTOR_PROFILE = "doctor/updateUser"
export const AGENCY_LIST = "agency/List"
export const GET_PATIENT_DETAIL = "doctor/requestPatinetDetails?id="
export const GET_Clinic_DETAIL = "doctor/ClinicDetails?id="
export const APPOINTMENTS_CONFIRM="doctor/appointmentChangeStatus"
export const ADD_PROBLEM="doctor/addProblems"
export const GET_PROBLEM="doctor/getProblemsList?patientId="
export const DELETE_PROBLEM="doctor/deleteProblam?id="
export const REQUEST="request"
export const REQUEST_UPDATE="request?requestId="
export const PATIENT_REQUEST = "doctor/patientRequest"
export const AGENCY_DOCTOR_REQUEST_UPDATE="request/updateAgencyDoctor"
export const AGENCY_DOCTOR_REQUEST_DETAILS="request/getAgencyDoctorDetails"


// patient flow
export const PATIENT_LOGIN ='patient/login'
export const PATIENT_SIGNUP = "patient/signUp"
export const ADDAGENCYPATIENT = "agency/addPatient"
export const PATIENT_GETPROFILE = "patient/getProfile"
export const PATIENT_UPDATE="patient/updateUser"
export const PATIENT_UPDATE_ADD_ADDRESS="patient/addPatientAddress"
export const PATIENT_REMOVE_ADDRESS="patient/removePatientAddress"
export const CLIENT_UPDATE_ADD_ADDRESS="patient/addPatientRequestAddress"
export const CLIENT_REMOVE_ADDRESS="patient/removePatientRequestAddress"
export const TEAM_MEMBER="agency/teamMember"
export const GET_TEAM_PATIENT="agency/teamMemberByAgencyId"
export const GET_PROVIDER_LIST="patient/getProviderList"
export const APPOINTMENTS="appointments"
export const CLINIC_LIST="doctor/clinicRequestList?limit=100"
export const GetJoinRequest="agency/patinetGetJoinRequest"
export const PATIENTUPDATEJOINREQUEST="agency/patinetUpdateJoinRequest"
export const QUESTIONNAIRE="/api/v1/questionnaires"
export const QUESTIONNAIRE_CREATE="/api/v1/questionnaires/create"
export const CATEGORIES_CREATE="/api/v1/categories/create"
export const CATEGORIES="/api/v1/categories"
export const ADD_QUESTIONS="/api/v1/categories/addQuestion"
export const UPLOAD_PREDATA="/api/v1/questionnaires/upload-predata"
export const GET_DIAGONSIS="/services/getDiagnosis"
export const GET_CARDTYPE="slots/cardType"

// stripe
export const STRIPE_CREATESESSION="stripe/createSession?id="

import React, { useEffect, useRef, useState } from "react";
import {
  Addpatientdocument,
  Deletepatientdocument,
  Getpatientdocument,
  uploadImage,
} from "../../../../api/helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../../helpers/ToastService";
import VerifyIdentity from "./VerifyIdentity";
import RequiredForms from "./RequiredForms";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  documentName: Yup.string().required("Document Name is required"),
  date: Yup.date().required("Date is required"),
  status: Yup.string().required("Status is required"),
  label: Yup.string().required("Label is required"),
  notes: Yup.string().required("Notes is required"),
  sentToPatient: Yup.string().required("Selection is required"),
  // docFile: Yup.mixed().required("File is required"),
});
const PatientDocuments = ({ info }) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [ismodal, setIsModal] = useState(false);
  const [image, setImages] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [data, setdata] = useState([]);
  const [uploadedDocdata, setuploadedDocdata] = useState([]);
  const [educationDocdata, seteducationDocdata] = useState([]);
  const fileInputRef = useRef(null);
  const [documenttype, setdocumenttype] = useState("sytemGeneratedDoc");
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const [activeTab, setActiveTab] = useState("Generated");
  const [VerifyIdentitymodal, setVerifyIdentitymodal] = useState(false);
  const [showRequiredForms, setShowRequiredForms] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleViewDocument = (imageUrl) => {
    setImageSrc(imageUrl);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setImageSrc("");
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const documentMap = {
    "Generated Documents": "sytemGeneratedDoc",
    "Uploaded Documents": "uploadedDoc",
    "Education Documents": "educationDoc",
  };
  // console.log(documenttype,"documenttype=====")
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    setIsModal(false);
    setImages(null);
    reset();
  };
  const onSubmit = async (data) => {
    if (!image) {
      ShowToast("Please Choose Attach File");
      return;
    }

    const payload = {
      documenttype: documenttype,
      ...data,
      attachFile: image,
      patientId: info?.patientId?._id,
      agencyId: profileData?._id,
      documentType: documenttype,
    };
    console.log("Form Data Submitted:", payload);
    try {
      const respo = await Addpatientdocument(payload);
      ShowToast(respo?.data?.message);
      Getdata();
      GetUploadedDocumentsdata();
      GetEducationDocumentsdata();
      CloseModal();
    } catch (error) {
      CloseModal();
    }
  };
  const Getdata = async () => {
    try {
      const respo = await Getpatientdocument(
        info?.patientId?._id,
        "sytemGeneratedDoc"
      );
      setdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const GetUploadedDocumentsdata = async () => {
    try {
      const respo = await Getpatientdocument(
        info?.patientId?._id,
        "uploadedDoc"
      );
      setuploadedDocdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const GetEducationDocumentsdata = async () => {
    try {
      const respo = await Getpatientdocument(
        info?.patientId?._id,
        "educationDoc"
      );
      seteducationDocdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getdata();
    GetEducationDocumentsdata();
    GetUploadedDocumentsdata();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Document?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await Deletepatientdocument(id);
      ShowToast(respo?.data?.message);
      Getdata();
      GetUploadedDocumentsdata();
      GetEducationDocumentsdata();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>Documents</h1>

        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          + Upload Document
        </button>
        <div style={{ display: "flex", alignSelf: "flex-start" }}>
          <button
            className={`tab-button__two ${
              activeTab === "Generated" ? "active-tab__two" : ""
            }`}
            onClick={() => handleTabClick("Generated")}
          >
            Generated Documents
          </button>
          <div style={{ width: 20 }} />
          <button
            className={`tab-button__two ${
              activeTab === "Uploaded" ? "active-tab__two" : ""
            }`}
            onClick={() => handleTabClick("Uploaded")}
          >
            Uploaded Documents
          </button>
          <div style={{ width: 20 }} />
          <button
            className={`tab-button__two ${
              activeTab === "Education" ? "active-tab__two" : ""
            }`}
            onClick={() => handleTabClick("Education")}
          >
            Education Documents
          </button>
          <div style={{ width: 20 }} />
          <button
            className={`tab-button__two ${
              activeTab === "VerifyIdentity" ? "active-tab__two" : ""
            }`}
            onClick={() => setVerifyIdentitymodal(true)}
          >
            Verify Your Identity
          </button>
        </div>
        <div
          className="tabs-container__three"
          style={{ display: "flex", width: "100%", justifyContent: "left" }}
        />
        {ismodal && (
          <div className="modal_PatientDocuments" id="addDocumentModal">
            <h2>Add Patient Document</h2>
            <div className="unique-pay-frequency">
              {Object.keys(documentMap).map((label) => (
                <button
                  key={label}
                  className={`unique-pay-frequency-btn ${
                    documenttype === documentMap[label] ? "unique-active" : ""
                  }`}
                  onClick={() => setdocumenttype(documentMap[label])}
                >
                  {label}
                </button>
              ))}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group_PatientDocuments">
                <label>Document Name</label>
                <input
                  type="text"
                  {...register("documentName")}
                  placeholder="Enter document name"
                />
                <p className="error-message">{errors.documentName?.message}</p>
              </div>

              <div className="form-group_PatientDocuments">
                <label>Date</label>
                <input type="date" {...register("date")} />
                <p className="error-message">{errors.date?.message}</p>
              </div>

              <div className="form-group_PatientDocuments">
                <label>Status</label>
                <select {...register("status")}>
                  <option value="">Select Status</option>
                  <option value="New">New</option>
                  <option value="Reviewed">Reviewed</option>
                  <option value="Signed">Signed</option>
                </select>
                <p className="error-message">{errors.status?.message}</p>
              </div>

              <div className="form-group_PatientDocuments">
                <label>Label</label>
                <select {...register("label")}>
                  <option value="">Select Label</option>
                  <option value="Legal Document">Legal Document</option>
                  <option value="Other">Other</option>
                </select>
                <p className="error-message">{errors.label?.message}</p>
              </div>

              <div className="form-group_PatientDocuments">
                <label>Notes</label>
                <textarea
                  {...register("notes")}
                  placeholder="Add any notes"
                  rows={3}
                />
                <p className="error-message">{errors.notes?.message}</p>
              </div>

              <div className="form-group_PatientDocuments">
                <label>Sent to Patient</label>
                <select {...register("sentToPatient")}>
                  <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <p className="error-message">{errors.sentToPatient?.message}</p>
              </div>

              <div className="form-group_PatientDocuments">
                <label>Attach File</label>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={imagePicker}
                  accept="image/*"
                />
                {/* {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: 100, height: 100 }} />} */}
                <p className="error-message">{errors.docFile?.message}</p>
              </div>

              <div className="modal-footer_PatientDocuments">
                <button
                  type="button"
                  className="cancel-button_PatientDocuments"
                  onClick={() => setIsModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button_PatientDocuments"
                  style={{ marginLeft: 10 }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
        {activeTab === "Generated" && (
          <>
            <h2>System Generated Documents</h2>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Label</th>
                  <th>Notes</th>
                  <th>Sent to Patient</th>
                  <th>File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 ? (
                  <tr>
                    <td colSpan="10">No data found</td>
                  </tr>
                ) : (
                  data.map((item, index) => (
                    <tr key={item._id || index}>
                      <td>{new Date(item.date).toLocaleDateString()}</td>
                      <td>{item.documentName}</td>
                      <td>{item.status}</td>
                      <td>{item.label}</td>
                      <td>{item.notes}</td>
                      <td>{item.sentToPatient}</td>
                      {/* <td>{item.attachFile}</td> */}
                      <td>
                        <button
                          className="btn"
                          onClick={() => handleViewDocument(item.attachFile)}
                        >
                          View Document
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn"
                          style={{ backgroundColor: "red", color: "white" }}
                          onClick={() => handleRemove(item._id)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
        {activeTab === "Uploaded" && (
          <>
            <h2>Uploaded Documents</h2>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Label</th>
                  <th>Notes</th>
                  <th>Sent to Patient</th>
                  <th>File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {uploadedDocdata.length === 0 ? (
                  <tr>
                    <td colSpan="10">No data found</td>
                  </tr>
                ) : (
                  uploadedDocdata.map((item, index) => (
                    <tr key={item._id || index}>
                      <td>{new Date(item.date).toLocaleDateString()}</td>
                      <td>{item.documentName}</td>
                      <td>{item.status}</td>
                      <td>{item.label}</td>
                      <td>{item.notes}</td>
                      <td>{item.sentToPatient}</td>
                      {/* <td>{item.attachFile}</td> */}
                      <td>
                        <button
                          className="btn"
                          onClick={() => handleViewDocument(item.attachFile)}
                        >
                          View Document
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn"
                          style={{ backgroundColor: "red", color: "white" }}
                          onClick={() => handleRemove(item._id)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
        {activeTab === "Education" && (
          <>
            <h2>Patient Education Documents</h2>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Label</th>
                  <th>Notes</th>
                  <th>Sent to Patient</th>
                  <th>File</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {educationDocdata.length === 0 ? (
                  <tr>
                    <td colSpan="10">No data found</td>
                  </tr>
                ) : (
                  educationDocdata.map((item, index) => (
                    <tr key={item._id || index}>
                      <td>{new Date(item.date).toLocaleDateString()}</td>
                      <td>{item.documentName}</td>
                      <td>{item.status}</td>
                      <td>{item.label}</td>
                      <td>{item.notes}</td>
                      <td>{item.sentToPatient}</td>
                      <td>
                        <button
                          className="btn"
                          onClick={() => handleViewDocument(item.attachFile)}
                        >
                          View Document
                        </button>
                      </td>

                      <td>
                        <button
                          className="btn"
                          style={{ backgroundColor: "red", color: "white" }}
                          onClick={() => handleRemove(item._id)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}

        {VerifyIdentitymodal && (
          <VerifyIdentity
            onClose={() => setVerifyIdentitymodal(false)}
            onSuccess={() => {
              setVerifyIdentitymodal(false);
              setShowRequiredForms(true);
            }}
          />
        )}
        {showRequiredForms && (
          <RequiredForms onClose={() => setShowRequiredForms(false)} />
        )}
        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Document</DialogTitle>
          <DialogContent>
            <img
              src={imageSrc}
              alt="Document"
              style={{ width: "100%", height: "70vh", objectFit: "contain" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default PatientDocuments;

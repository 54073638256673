import React, { useEffect, useState } from "react";
import { FetchQuestionnaireList } from "../../../api/helper";
import { Trash2 } from "lucide-react";

const QuestionnaireList = () => {
  const [questionnaires, setquestionnaires] = useState([]);
  const GetQuestionnairelist = async () => {
    try {
      const response = await FetchQuestionnaireList();
      setquestionnaires(response?.data?.questionnaires);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetQuestionnairelist();
  }, []);
  return (
    <div className="questionnaire-list-container">
      <h1 className="questionnaire-list-title">Questionnaire List</h1>
      <ul className="questionnaire-list">
        {questionnaires?.map((item) => (
          <li key={item?.id} className="questionnaire-list-item">
            {item?.label}
            <Trash2
              className={`questionnaire-delete-icon ${
                item?.isPreData ? "disabled-icon" : "active-icon"
              }`}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuestionnaireList;

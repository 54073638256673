import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import {
  AddconsentManagement,
  GetconsentManagement,
  RemoveconsentManagement,
  UpdatedataconsentManagement,
} from "../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  Title: Yup.string().required("Consent Title is required"),
  Category: Yup.string().required("Category is required"),
  Signature: Yup.string().required("Please select if a signature is required"),
  ExpirationDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(new Date(), "Expiration date cannot be in the past"),
  Treatment: Yup.string().required(
    "Please select if it's required for treatment"
  ),
  Content: Yup.string().required("Consent Content is required"),
  VersionNumber: Yup.string().required("Version Number is required"),
});
const ConsentManagement = () => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [ismodal, setIsModal] = useState(false);
  const [consentdata, setconsentdata] = useState([]);
  
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
      ],
    },
    theme: "snow",
    placeholder: "Type your consent content here...",
  });
  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    setIsModal(false);
    window.location.reload();
    reset();
  };
  const onSubmit = async (data) => {
    // console.log("Form Data:", data);
    // console.log("Form Data:", data);
    data.agencyId = profiledata?._id;
    // return
    try {
      const respo = await AddconsentManagement(data);
      //   console.log(respo?.data?.data,"respopost=====")
      ShowToast(respo?.data?.message);
      CloseModal();
      FetchConsentManagement();
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        const content = quill.root.innerHTML;
        setValue("Content", content);
      });
    }
  }, [quill, setValue]);

  const FetchConsentManagement = async () => {
    try {
      const respo = await GetconsentManagement(profiledata?._id);
      console.log(respo?.data?.data?.data, "dataget========");
      setconsentdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchConsentManagement();
  }, []);

  const DeleteConsentManagement = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Consent?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await RemoveconsentManagement(id);
      ShowToast(respo?.data?.message);
      FetchConsentManagement();
    } catch (error) {}
  };
  const UpdateonsentManagement = async (item) => {
    const data = {
      itemId: item._id,
      status: item.status === "active" ? "Inactive" : "active",
    };

    try {
      const respo = await UpdatedataconsentManagement(data);
      ShowToast(respo?.data?.message);
      FetchConsentManagement();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container_NotesTabScreen" style={{ width: "100%" }}>
        <h1>Consent Management</h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add Consent
        </button>
        {ismodal && (
          <div className="modal_PatientDocuments" id="addDocumentModal">
            <div
              style={{
                fontSize: "20px",
                cursor: "pointer",
                width: 50,
                position: "absolute",
                right: 18,
                top: 27,
              }}
              onClick={CloseModal}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                style={{ height: 20, width: 20 }}
              />
            </div>
            <div>
              <h1>Agency Consent Management</h1>
              <form
                id="consent-form"
                onSubmit={handleSubmit(onSubmit)}
                className="agency-consent-form"
              >
                <label htmlFor="consent-title">Consent Title</label>
                <input type="text" id="consent-title" {...register("Title")} />
                {errors.Title && (
                  <p className="error">{errors.Title.message}</p>
                )}

                <label htmlFor="consent-category">Category</label>
                <select id="consent-category" {...register("Category")}>
                  <option value="">Select Category</option>
                  <optgroup label="HIPAA & Privacy">
                    <option value="HIPAA Compliance">HIPAA Compliance</option>
                    <option value="Release of Medical Records">
                      Release of Medical Records
                    </option>
                    <option value="Third-Party Data Sharing">
                      Third-Party Data Sharing
                    </option>
                  </optgroup>
                  <optgroup label="Treatment & Medical Care">
                    <option value="General Treatment">General Treatment</option>
                    <option value="Mental Health Treatment">
                      Mental Health Treatment
                    </option>
                    <option value="Informed Therapy Consent">
                      Informed Therapy Consent
                    </option>
                  </optgroup>
                  <optgroup label="Financial & Insurance">
                    <option value="Financial Responsibility">
                      Financial Responsibility
                    </option>
                    <option value="Insurance Billing">Insurance Billing</option>
                    <option value="Out-of-Pocket Payments">
                      Out-of-Pocket Payments
                    </option>
                  </optgroup>
                  <optgroup label="Medication & Prescription">
                    <option value="Medication History Access">
                      Medication History Access
                    </option>
                    <option value="Controlled Substance Agreement">
                      Controlled Substance Agreement
                    </option>
                    <option value="Psychotropic Medication">
                      Psychotropic Medication
                    </option>
                  </optgroup>
                  <optgroup label="Telehealth & Remote Services">
                    <option value="Telehealth Consent">
                      Telehealth Consent
                    </option>
                    <option value="Virtual Visit Recording">
                      Virtual Visit Recording
                    </option>
                    <option value="Electronic Communication Consent">
                      Electronic Communication Consent
                    </option>
                  </optgroup>
                  <optgroup label="Legal & Compliance">
                    <option value="Court-Ordered Treatment">
                      Court-Ordered Treatment
                    </option>
                    <option value="Guardian/Power of Attorney Consent">
                      Guardian/Power of Attorney Consent
                    </option>
                  </optgroup>
                  <optgroup label="Specialized Consents">
                    <option value="Substance Use Treatment">
                      Substance Use Treatment
                    </option>
                    <option value="Genetic Testing">Genetic Testing</option>
                    <option value="Research Study Participation">
                      Research Study Participation
                    </option>
                  </optgroup>
                </select>
                {errors.Category && (
                  <p className="error">{errors.Category.message}</p>
                )}

                <label htmlFor="version-number">Version Number</label>
                <input
                  type="number"
                  id="VersionNumber"
                  {...register("VersionNumber")}
                />
                {errors.VersionNumber && (
                  <p className="error">{errors.VersionNumber.message}</p>
                )}
                <label htmlFor="requires-signature">Requires Signature?</label>
                <select id="requires-signature" {...register("Signature")}>
                  <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {errors.Signature && (
                  <p className="error">{errors.Signature.message}</p>
                )}

                <label htmlFor="expiration-date">
                  Expiration Date (Optional)
                </label>
                <input
                  type="date"
                  id="expiration-date"
                  {...register("ExpirationDate")}
                />
                {errors.ExpirationDate && (
                  <p className="error">{errors.ExpirationDate.message}</p>
                )}

                <label htmlFor="required-treatment">
                  Required for Treatment?
                </label>
                <select id="required-treatment" {...register("Treatment")}>
                  <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {errors.Treatment && (
                  <p className="error">{errors.Treatment.message}</p>
                )}

                <label>Consent Content</label>
                <div style={{ marginBottom: 10 }}>
                  <div ref={quillRef} className="editor-content" />
                </div>
                {errors.Content && (
                  <p className="error">{errors.Content.message}</p>
                )}

                <button
                  type="submit"
                  style={{ color: "black", backgroundColor: "#fafafa" }}
                >
                  Add Consent
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Category</th>
                <th>Version</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {consentdata.length === 0 ? (
                <tr>
                  <td colSpan="10">No data found</td>
                </tr>
              ) : (
                consentdata.map((item, index) => (
                  <tr key={item._id || index}>
                    <td>{item.Title}</td>
                    <td>{item.Category}</td>
                    <td>{item.VersionNumber}</td>
                    <td>
                      <button
                        onClick={() => UpdateonsentManagement(item)}
                        style={{ width: 100 }}
                        className={`toggle-btn ${
                          item.status === "active" ? "active" : "inactive"
                        }`}
                      >
                        {item.status}
                      </button>
                    </td>
                    <td>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                          style={{
                            width: 100,
                            marginBottom: 7,
                            backgroundColor: "lightgrey",
                            color: "black",
                          }}
                        >
                          View
                        </button>
                        <button
                          className="delete-btn"
                          style={{ width: 100 }}
                          onClick={() => DeleteConsentManagement(item._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ConsentManagement;

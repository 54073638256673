import React, { useEffect, useState } from "react";
import { Trash2 } from "lucide-react";
import { Fetchcategoriescreate, updatePreData } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const QuestionCategoryList = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setcategories] = useState([]);
  const GetQuestionnairelist = async () => {
    try {
      const response = await Fetchcategoriescreate();
      setcategories(response?.data?.categories);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetQuestionnairelist();
  }, []);

  const handleUploadData = async () => {
    setLoading(true);
    try {
      const response = await updatePreData();
      if (response) {
        ShowToast("Data uploaded successfully");
        GetQuestionnairelist()
      }
    } catch (error) {
      console.log(error);
      ShowToast("Something went wrong ! Please check console");
      setLoading(false);
    }
  };

  return (
    <div className="question-category-container">
      <h1 className="question-category-title">Question Category List</h1>
      <div className="question-category-button-container">
        <button
          onClick={handleUploadData}
          disabled={loading}
          className="question-category-button"
        >
          {loading ? "Uploading..." : "Add Pre Existing Data To Database"}
        </button>
      </div>
      <ul className="question-category-list">
        {categories.map((item, index) => (
          <React.Fragment key={index}>
            <li className="question-category-list-item">
              {item.name}
              <Trash2 className="question-category-trash-icon" />
            </li>

            {item.questions && (
              <ul className="question-category-sublist">
                {item.questions.map((question, index) => (
                  <li key={index} className="question-category-subitem">
                    {question}
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default QuestionCategoryList;

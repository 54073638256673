import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AddPatientCardagencyside,
  RemovePatientCardagencyside,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  cardHolderName: Yup.string().required("Card Name is required"),

  cardNumber: Yup.string()
    .required("Card number is required")
    .matches(/^[0-9]{16}$/, "Card number must be 16 digits"),
  expMonth: Yup.number()
    .required("Expiration month is required")
    .min(1, "Invalid month")
    .max(12, "Invalid month"),
  expYear: Yup.number()
    .required("Expiration year is required")
    .min(new Date().getFullYear(), "Year cannot be in the past")
    .max(new Date().getFullYear() + 10, "Year cannot exceed +10 years"),
  CVV: Yup.string()
    .required("CVV is required")
    .matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits"),
});
function PatinetPrivatePay({ info, getDteails }) {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    reset();
  };
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    console.log("Form Submitted:", data);
    data.id = info?._id;
    try {
      const response = await AddPatientCardagencyside(data);
      ShowToast(response?.data?.message);
      console.log(response?.data?.data, "response---helloo-=====");
      getDteails();
      reset();
      closeModal();
      console.log(info, "info=====");
    } catch (error) {}
  };
  const handleRemovecard = async (id) => {
    console.log(`Removing card with id: ${id}`);
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this card?"
    );
    if (!isConfirmed) {
      console.log("Address removal was canceled.");
      return;
    }
    const requestData = {
      patientRequestId: info?._id,
      cardId: id,
    };
    try {
      const response = await RemovePatientCardagencyside(requestData);
      ShowToast(response?.data?.message);
      getDteails();
    } catch (error) {
      console.log(error, "error====");
    }
  };
  return (
    <>
      {showModal && (
       <div className="modal_PaymentForm" id="addDocumentModal" style={{backgroundColor:'none'}}>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            border:'1px solid black',
            borderRadius:7
          }}
        >
          
          <div className="payment-container">
          <div
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  width: 50,
                position:'absolute',right:18,
                top:27
                }}
              onClick={closeModal}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                  style={{ height: 20, width: 20 }}
                />
              </div>
            <h1>Credit Card Payment Form</h1>
            <p className="terms_twoo">
              By continuing you agree to our <a>Terms &amp; Conditions</a>
            </p>
            <form id="paymentForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="radio-group">
                <div className="radio-group_label">
                  <input
                    type="radio"
                    {...register("cardType", {
                      required: "Card type is required",
                    })}
                    value="credit"
                    required
                  />
                  <label style={{ marginBottom: 20 }}> Credit Card</label>
                </div>

                <div className="radio-group_label">
                  <input
                    type="radio"
                    {...register("cardType", {
                      required: "Card type is required",
                    })}
                    value="debit"
                  />

                  <label style={{ marginBottom: 20 }}> Debit Card</label>
                </div>
              </div>
              <div className="form-group_twoooo">
                <label htmlFor="cardHolder">Card Holder Name</label>
                <input
                  type="text"
                  id="cardHolderName"
                  placeholder="Enter Name"
                  {...register("cardHolderName")}
                />
                {errors.cardHolderName && (
                  <p className="error-text">{errors.cardHolderName.message}</p>
                )}
              </div>
              <div className="form-group_twoooo">
                <label htmlFor="cardNumber">Card Number</label>
                <input
                  type="text"
                  id="cardNumber"
                  placeholder="**** **** **** 1234"
                  maxLength={16}
                  {...register("cardNumber")}
                />
                {errors.cardNumber && (
                  <p className="error-text">{errors.cardNumber.message}</p>
                )}
                <div className="card-icons">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg"
                    alt="Visa"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg"
                    alt="MasterCard"
                  />
                </div>
              </div>
              <div className="form-inline_twoooo">
                <div className="form-group_twoooo" style={{ width: "30%" }}>
                  <label htmlFor="expMonth">Exp Month</label>
                  <select id="expMonth" {...register("expMonth")}>
                    <option value="" disabled selected>
                      Select
                    </option>
                    {[...Array(12)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  {errors.expMonth && (
                    <p className="error-text">{errors.expMonth.message}</p>
                  )}
                </div>
                <div className="form-group_twoooo" style={{ width: "30%" }}>
                  <label htmlFor="expYear">Exp Year</label>
                  <select id="expYear" {...register("expYear")}>
                    <option value="" disabled selected>
                      Select
                    </option>
                    {[...Array(11)].map((_, i) => (
                      <option key={i} value={new Date().getFullYear() + i}>
                        {new Date().getFullYear() + i}
                      </option>
                    ))}
                  </select>
                  {errors.expYear && (
                    <p className="error-text">{errors.expYear.message}</p>
                  )}
                </div>
                <div className="form-group_twoooo" style={{ width: "30%" }}>
                  <label htmlFor="cvv">CVV</label>
                  <input
                    type="text"
                    id="CVV"
                    placeholder="123"
                    maxLength={4}
                    {...register("CVV")}
                  />
                  {errors.CVV && (
                    <p className="error-text">{errors.CVV.message}</p>
                  )}
                </div>
              </div>
              <button className="btn-submit">+ Add New Card Now</button>
            </form>
          </div>
        </div>
        </div>
      )}
      <div className="card-list" id="cardList">
        <h2 style={{textAlign:'left'}}>Saved Cards</h2>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" ,marginBottom:10  }}
          onClick={openModal}
        >
         Add Card
        </button>
        {info?.patientCards?.length > 0 ? (
          info.patientCards.map((card) => (
            <div
              key={card._id}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                className="card-item"
                id="card-1234"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{card.cardType}</span>
                  <span style={{ marginLeft: 5 }}>
                    {card.cardNumber + " | Exp: "}
                  </span>
                  <span>{card.expMonth + "/" + card?.expYear}</span>
                </div>
                <button onClick={() => handleRemovecard(card._id)}>
                  Remove
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No cards available.</p>
        )}
      </div>
      {/* <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          marginBottom:10
        }}
      >
        <h3>Private Pay Options</h3>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,

          }}
          onClick={openModal}
        >
          Add Card
        </div>
      </div>
      {info?.patientCards?.length > 0 ? (
        info.patientCards.map((card) => (
          <div
            key={card._id}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "16.4%" }}
            >
              <div style={{ textAlign: "left" }}>Card Number:</div>
              <strong style={{ textAlign: "left" }}> {card.cardNumber}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "16.4%" }}
            >
              <div style={{ textAlign: "left" }}>expMonth:</div>
              <strong style={{ textAlign: "left" }}>{card.expMonth}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "16.4%" }}
            >
              <div style={{ textAlign: "left" }}>expYear:</div>
              <strong style={{ textAlign: "left" }}>{card.expYear}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "16.4%" }}
            >
              <div style={{ textAlign: "left" }}>CVV:</div>
              <strong style={{ textAlign: "left" }}> {card.CVV}</strong>
            </div>
         
            <button
              style={{
                padding: "5px 10px",
                backgroundColor: "#d9534f",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                height:50
              }}
              onClick={() => handleRemovecard(card._id)}
            >
              Remove
            </button>
          </div>
        ))
      ) : (
        <p>No addresses available.</p>
      )}
      {showModal && (
        <div
          style={{
            position: "absolute",
            top: "0.1%",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",

            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              width: "600px",
              maxHeight: "90vh", // Maximum height for the modal content
              overflowY: "auto", // Enable scrolling if content overflows
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div id="private-pay-section">
                <div className="input-group">
                  <label htmlFor="card_number">Card Number</label>
                  <input
                    type="text"
                    id="cardNumber"
                    {...register("cardNumber")}
                    placeholder="Enter your card number"
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.cardNumber?.message}
                  </p>
                </div>

                <div className="input-group">
                  <label htmlFor="exp_month">Exp Month</label>
                  <select id="expMonth" {...register("expMonth")}>
                    {[...Array(12)].map((_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {String(index + 1).padStart(2, "0")}
                      </option>
                    ))}
                  </select>
                  <p className="error" style={{ color: "red" }}>
                    {errors.expMonth?.message}
                  </p>
                </div>

                <div className="input-group">
                  <label htmlFor="exp_year">Exp Year</label>
                  <select id="expYear" {...register("expYear")}>
                    {[...Array(10)].map((_, index) => {
                      const year = new Date().getFullYear() + index;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                  <p className="error" style={{ color: "red" }}>
                    {errors.expYear?.message}
                  </p>
                </div>

                <div className="input-group">
                  <label htmlFor="cvv">CVV</label>
                  <input
                    type="text"
                    id="CVV"
                    {...register("CVV")}
                    placeholder="CVV"
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.CVV?.message}
                  </p>
                </div>
              </div>
              <button className="submit-btn" type="submit">
                Submit Payment
              </button>
              <button
              type="button"
              onClick={closeModal}
              style={{
                marginBottom: 20,
                marginTop: 4,
                alignSelf: "center",
                width: "100%",
                height: "50px",
                // backgroundColor: "red",
                color: "black",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
                border: "1px solid grey",
              }}
            >
              Cancel
            </button>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            </form>
          </div>
        </div>
      )} */}
    </>
  );
}

export default PatinetPrivatePay;

import React, { useEffect, useState } from "react";
import {
  FetchQuestionnaireList,
  Uploadcategoriescreate,
} from "../../../api/helper";
import { CATEGORIES_CREATE } from "../../../api/url";
import { ShowToast } from "../../../helpers/ToastService";
import { Trash2 } from "lucide-react";
import { Fetchcategoriescreate, updatePreData } from "../../../api/helper";

const AddCategory = () => {
  const [form, setForm] = useState({
    name: "",
    abbreviation: "",
  });
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionnaires, setquestionnaires] = useState([]);
   const [categories, setcategories] = useState([]);
     const [ismodal, setIsModal] = useState(false);
         const openModal = () => {
           setIsModal(true);
         };
         const CloseModal = () => {
           setIsModal(false);
         };
    const GetFetchcategoriescreate = async () => {
      try {
        const response = await Fetchcategoriescreate();
        setcategories(response?.data?.categories);
      } catch (error) {
        console.log(error);
      }
    };
  
  
    const handleUploadData = async () => {
      setLoading(true);
      try {
        const response = await updatePreData();
        if (response) {
          ShowToast("Data uploaded successfully");
          GetQuestionnairelist()
        }
      } catch (error) {
        console.log(error);
        ShowToast("Something went wrong ! Please check console");
        setLoading(false);
      }
    };
  const GetQuestionnairelist = async () => {
    try {
      const response = await FetchQuestionnaireList();
      setquestionnaires(response?.data?.questionnaires);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetQuestionnairelist();
    GetFetchcategoriescreate()
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (answers.length === 0) {
      ShowToast("Please ensure all answers are filled in.");
      return;
    }
    if (form.name === "" || form.abbreviation === "") {
      ShowToast("Please fill in all fields.");
      return;
    }
    setLoading(true);
    const data = {
      ...form,
      answers: JSON.stringify(answers),
    };
    try {
      const response = await Uploadcategoriescreate(data);

      if (response) {
        ShowToast("Category created successfully");
        GetFetchcategoriescreate()
      CloseModal()
      }
    } catch (error) {
      CloseModal()
      console.error("Error creating category:", error);
      ShowToast(
        error?.response?.data?.message ||
          "Something went wrong ! Please check console"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
    {ismodal &&
    <div className="add-question-container">
      <h1 className="add-question-title">Add New Question</h1>

      <form onSubmit={handleSubmit} className="add-question-form">
        <div className="add-form-group">
          <label className="add-form-label">Category Name</label>
          <input
            type="text"
            name="categoryTitle"
            className="add-form-input"
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
        </div>

        <div className="add-form-group">
          <label className="add-form-label">Category Abbreviation</label>
          <select
            onChange={(e) => setForm({ ...form, abbreviation: e.target.value })}
            className="add-form-select"
          >
            <option value="">Select Abbreviation</option>
            {questionnaires.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.id}
              </option>
            ))}
          </select>
        </div>

        <div className="add-form-group">
          <label className="add-form-label">Answers</label>
          {answers.map((answer, index) => (
            <div key={index} className="add-answer-row">
              <input
                type="text"
                value={answer.name}
                onChange={(e) => {
                  const newAnswers = [...answers];
                  newAnswers[index].name = e.target.value;
                  setAnswers(newAnswers);
                }}
                placeholder="Answer text"
                className="add-answer-input"
                required
              />
              <input
                type="number"
                value={answer.value}
                onChange={(e) => {
                  const newAnswers = [...answers];
                  newAnswers[index].value = Number(e.target.value);
                  setAnswers(newAnswers);
                }}
                placeholder="Value"
                className="add-answer-value"
                min="0"
                required
              />
              {answers.length > 1 && (
                <button
                  type="button"
                  onClick={() =>
                    setAnswers(answers.filter((_, i) => i !== index))
                  }
                  className="add-remove-answer-btn"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={() => setAnswers([...answers, { name: "", value: 0 }])}
            className="add-add-answer-btn"
          >
            Add Answer
          </button>
        </div>

        <button type="submit" disabled={loading} className="add-submit-btn">
          {loading ? "Creating..." : "Add Question"}
        </button>
      </form>
    </div>
}
    <div style={{alignItems:'center',display:'flex',justifyContent:'space-between'}}>
  <h1>Questions </h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add New Question
        </button>
  </div>
    <div className="question-category-container">
    <h1 className="question-category-title">Question Category List</h1>
    <div className="question-category-button-container">
      <button
        onClick={handleUploadData}
        disabled={loading}
        className="question-category-button"
      >
        {loading ? "Uploading..." : "Add Pre Existing Data To Database"}
      </button>
    </div>
    <ul className="question-category-list">
      {categories.map((item, index) => (
        <React.Fragment key={index}>
          <li className="question-category-list-item">
            {item.name}
            <Trash2 className="question-category-trash-icon" />
          </li>

          {item.questions && (
            <ul className="question-category-sublist">
              {item.questions.map((question, index) => (
                <li key={index} className="question-category-subitem">
                  {question}
                </li>
              ))}
            </ul>
          )}
        </React.Fragment>
      ))}
    </ul>
  </div>
  </>
  );
};

export default AddCategory;

import React, { useEffect, useState } from "react";
import {
  FamilyHistoryonsubmit,
  FamilyHistoryonsubmitUpdate,
  FetchFamilyHistory,
  FetchLifestyleDeterminants,
  FetchMedicalHistory,
  FetchPsychiatricHistory,
  GetSurgicalHistory,
  LifestyleDeterminantsnsubmit,
  LifestyleDeterminantsonsubmitUpdate,
  MedicalHistoryonsubmit,
  MedicalHistoryonsubmitUpdate,
  PsychiatricHistoryonsubmit,
  PsychiatricHistoryonsubmitUpdate,
  SurgicalHistoryonsubmit,
  SurgicalHistoryonsubmitUpdate,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import {
  FamilyHistorydata,
  Medicalhistoycategories,
  PsychiatricHistorydata,
  SurgicalHistorydata,
} from "./Historydata";

function ClientHistory({ info }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [ismedicalHistory, setismedicalHistory] = useState(false);
  const [isfamilyHistory, setisfamilyHistory] = useState(false);
  const [ispsychiatricHistory, setispsychiatricHistory] = useState(false);
  const [islifestyleDeterminants, setislifestyleDeterminants] = useState(false);
  const [familyhistoryget, setfamilyhistoryget] = useState(null);
  const [medicalhistoryget, setmedicalhistoryget] = useState(null);
  const [psychiatricdata, setpsychiatricdata] = useState(null);
  const [LifestyleDeterminantsget, setLifestyleDeterminantsget] =
    useState(null);
  const [SurgicalHistoryget, setSurgicalHistoryget] = useState(null);
  const [formData, setFormData] = useState({
    generalHealthConditions: {
      noHealthConcern: false,
      arthritis: false,
      asthma: false,
      bleedingDisorder: false,
      cadUnder55: false,
      copd: false,
      diabetes: false,
    },
    cardiovascularAndOthers: {
      heartAttack: false,
      heartDisease: false,
      highCholesterol: false,
      hypertension: false,
      mentalIllness: false,
      osteoporosis: false,
      stroke: false,
    },
    cancer: {
      breastCA: false,
      colonCA: false,
      ovarianCA: false,
      prostateCA: false,
      uterineCA: false,
      otherCA: false,
    },
  });
  const [medicalData, setMedicalData] = useState({
    head: {
      trauma: false,
      migraines: false,
      concussion: false,
      skullFracture: false,
    },
    eyes: {
      blindness: false,
      cataracts: false,
      glaucoma: false,
      wearsGlassesContacts: false,
      eyeInfection: false,
    },
    ears: {
      hearingLoss: false,
      tinnitus: false,
      earInfections: false,
      hearingAids: false,
    },
    noseSinuses: {
      sinusInfection: false,
      allergicRhinitis: false,
      nasalFracture: false,
      nasalPolyps: false,
    },
    mouthThroatTeeth: {
      gumDisease: false,
      toothDecay: false,
      tonsillitis: false,
      dentures: false,
    },
    cardiovascular: {
      hypertension: false,
      angina: false,
      heartAttack: false,
      dysrhythmia: false,
    },
    respiratory: {
      asthma: false,
      copd: false,
      pneumonia: false,
      bronchitis: false,
    },
    neurological: {
      seizures: false,
      stroke: false,
      migraines: false,
      multipleSclerosis: false,
    },
    musculoskeletal: {
      arthritis: false,
      gout: false,
      fractures: false,
      muscleStrain: false,
    },
    skin: {
      psoriasis: false,
      eczema: false,
      dermatitis: false,
      skinCancer: false,
    },
    endocrine: {
      diabetesType1: false,
      diabetesType2: false,
      hypothyroidism: false,
      hyperthyroidism: false,
    },
    infectiousDiseases: {
      tuberculosis: false,
      hiv: false,
      hepatitisB: false,
      hepatitisC: false,
    },
    hematologicOncologic: {
      anemia: false,
      leukemia: false,
      lymphoma: false,
      clottingDisorders: false,
    },
    psychiatric: {
      anxiety: false,
      depression: false,
      bipolarDisorder: false,
      schizophrenia: false,
    },
    genitourinary: {
      kidneyStones: false,
      uti: false,
      incontinence: false,
      chronicKidneyDisease: false,
    },
    gastrointestinal: {
      gerd: false,
      crohnsDisease: false,
      ulcerativeColitis: false,
      irritableBowelSyndrome: false,
    },
    immuneSystem: {
      autoimmuneDisorders: false,
      lupus: false,
      rheumatoidArthritis: false,
    },
  });
  const [psychiatricHistoryData, setPsychiatricHistoryData] = useState({
    psychiatricConditions: {
      anxietyDisorders: false,
      depression: false,
      bipolarDisorder: false,
      schizophrenia: false,
      obsessiveCompulsiveDisorder: false,
      postTraumaticStressDisorder: false,
      substanceUseDisorders: false,
      eatingDisorders: false,
      autismSpectrumDisorder: false,
      adhd: false,
      personalityDisorders: false,
    },
    neurocognitiveDisorders: {
      dementia: false,
      alzheimersDisease: false,
      mildCognitiveImpairment: false,
      parkinsonsDisease: false,
      huntingtonsDisease: false,
    },
    developmentalDisorders: {
      intellectualDisability: false,
      speechLanguageDisorders: false,
      learningDisorders: false,
      touretteSyndrome: false,
    },

    symptoms: {
      suicidalThoughts: false,
      selfHarm: false,
      hearingSeeingThings: false,
      delusions: false,
      anxietyOrPanicAttacks: false,
      moodSwings: false,
      paranoia: false,
      otherSymptoms: false,
    },
    mentalHealthTreatmentHistory: {
      hospitalizedForPsychiatricCondition: false,
      therapyForMentalHealthConcerns: false,
      prescribedPsychiatricMedications: false,
      historyOfElectroconvulsiveTherapy: false,
      otherTreatmentHistory: false,
    },
    substanceUse: {
      alcoholUse: false,
      marijuanaUse: false,
      illicitDrugUse: false,
      intravenousDrugUse: false,
      noSubstanceUse: false,
    },
    familypaychiaticHistory: {
      anxietyDisorders: false,
      depression: false,
      bipolarDisorder: false,
      schizophrenia: false,
      ptsd: false,
      substanceUseDisorders: false,
      otherfamilypaychiaticHistory: false,
    },
    socialEnvironmentalstressors: {
      financialProblems: false,
      rlationshipIssues: false,
      jobRelatedStress: false,
      familyConflicts: false,
      traumaPhysicalEmotionalSexual: false,
      othersocialEnvironmentalstressors: false,
    },
    cognitiveandBehavioralPatterns: {
      difficultyConcentration: false,
      decisionMakingProblems: false,
      problemSolvingChallenges: false,
      impulsiveBehaviors: false,
      othercognitiveandBehavioralPatterns: false,
    },
    sleepandEnergyLevels: {
      difficultyFallingAsleep: false,
      frequentWaking: false,
      oversleeping: false,
      chronicFatigue: false,
      lowEnergyLevels: false,
    },
  });
  const [LifestyleDeterminants, setLifestyleDeterminants] = useState({
    tobaccoUse: {
      currentEverydaySmoker: false,
      currentSomeDaySmoker: false,
      formerSmoker: false,
      heavyTobaccoSmoker: false,
      lightTobaccoSmoker: false,
      neverSmoker: false,
      smokerCurrentStatusUnknown: false,
      unknownIfEverSmoked: false,
    },
    alcoholUse: {
      doNotDrink: false,
      drinkDaily: false,
      frequentlyDrink: false,
      historyOfAlcoholism: false,
      occasionalDrink: false,
    },
    substanceUse: {
      ivdu: false,
      illicitDrugUse: false,
      noIllicitDrugUse: false,
      prescriptionDrugMisuse: false,
      marijuanaUse: false,
      cocaineUse: false,
      heroinUse: false,
      methamphetamineUse: false,
      hallucinogenUse: false,
      inhalantUse: false,
      stimulantUse: false,
      sedativeUse: false,
      opioidUse: false,
    },
    cardiovascularHealth: {
      eatHealthyMeals: false,
      regularExercise: false,
      takeDailyAspirin: false,
    },
    patternsHistorySubstanceUse: {
      polysubstanceUse: false,
      hxOfSubstanceRehab: false,
      hxOfWithdrawalSymptoms: false,
      affectingDailyLife: false,
      impactingRelationships: false,
      impactingEmployment: false,
      other: false,
    },
    safetyPractices: {
      householdSmokeDetector: false,
      keepFirearmsInHome: false,
      wearSeatbelts: false,
    },
    sexualActivity: {
      exposureToSTI: false,
      nonHeterosexualActivity: false,
      notSexuallyActive: false,
      safeSexPractices: false,
      sexuallyActive: false,
    },
    birthGender: {
      male: false,
      female: false,
      undifferentiated: false,
    },
  });
  const [SurgicalHistoryform, setSurgicalHistoryform] = useState({
    cardiovascularSurgeries: {
      aneurysmRepair: false,
      cabg: false,
      carotidEndarterectomy: false,
      pacemakerDefibrillator: false,
      ptcaPci: false,
    },
    commonSurgeries: {
      appendectomy: false,
      backSurgery: false,
      bariatricSurgery: false,
      bilateralTubalLigation: false,
      breastResectionMastectomy: false,
      carpalTunnelRelease: false,
    },
    ophthalmologySurgeries: {
      cataractLensSurgery: false,
      lasik: false,
    },
    gynecologicalSurgeries: {
      cesareanSection: false,
      dilationAndCurettage: false,
      hysterectomy: false,
      tahBso: false,
    },
    urologicalSurgeries: {
      prostateSurgery: false,
      prostatectomy: false,
      turp: false,
      vasectomy: false,
    },
    orthopedicSurgeries: {
      hipArthroplasty: false,
      hipReplacement: false,
      kneeArthroplasty: false,
      rotatorCuffSurgery: false,
      spinalFusion: false,
    },
    entSurgeries: {
      sinusSurgery: false,
      tonsillectomyAdenoidectomy: false,
      nasalSurgery: false,
    },
    skinCancerSurgeries: {
      skinCancerExcision: false,
      hemorrhoidSurgery: false,
    },
    miscellaneousSurgeries: {
      cholecystectomy: false,
      laminectomy: false,
      spinalFusion: false,
    },
  });

  const Getfamilyhistory = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    try {
      const response = await FetchFamilyHistory(agencyId, patientId);
      // console.log(response?.data?.data, "Fetched Family History");
      // Populate formData with the fetched data
      const data = response?.data?.data;

      if (data && Object.keys(data).length > 0) {
        setfamilyhistoryget(data);
        setFormData({
          generalHealthConditions: data.generalHealthConditions || {},
          cardiovascularAndOthers: data.cardiovascularAndOthers || {},
          cancer: data.cancer || {},
        });
      } else {
        // console.log("No family history data found or data is empty.");
      }
    } catch (error) {
      console.log("Error fetching family history:", error);
    }
  };
  // console.log(LifestyleDeterminantsget,"LifestyleDeterminantsget======")
  useEffect(() => {
    Getfamilyhistory();
    GetMedicalHistory();
    Getpsychiatrichistory();
    GetLifestyleDeterminants();
    FetchSurgicalHistory();
  }, []);
  const FamilyHistorysave = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    const data = {
      agencyId,
      patientId,
      ...formData,
    };
    console.log(data, "save===data===>>>");
    try {
      const response = await FamilyHistoryonsubmit(data);
      Getfamilyhistory();
      console.log(response?.data?.data, "hii-=------");
      ShowToast(response?.data?.message);
      setisfamilyHistory(false);
    } catch (error) {
      console.error("Error submitting family history:", error);
    }
  };
  const FamilyHistoryUpdate = async () => {
    // const id = ;
    const data = {
      // id,
      ...formData,
    };
    try {
      const response = await FamilyHistoryonsubmitUpdate(
        familyhistoryget?._id,
        data
      );
      console.log(response?.data?.data, "hii-=--update----");
      ShowToast(response?.data?.message);
      Getfamilyhistory();
      setisfamilyHistory(false);
    } catch (error) {
      console.error("Error submitting family history:", error);
    }
  };
  const handleCheckboxChange = (category, key, value) => {
    setFormData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: value,
      },
    }));
  };

  const handleCheckboxLifestyleDeterminantsChange = (category, key, value) => {
    setLifestyleDeterminants((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: value,
      },
    }));
  };
  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const MedicalHistoryonSave = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    const data = {
      agencyId,
      patientId,
      ...medicalData,
    };
    // console.log(data, "save===data===>>>");

    try {
      const response = await MedicalHistoryonsubmit(data);
      // console.log(response?.data?.data, "hii-=--MedicalHistoryonsubmit----");
      ShowToast(response?.data?.message);
      GetMedicalHistory();
      closeModal()
    } catch (error) {
      console.error("Error submitting medical history:", error);
    }
  };
  const GetMedicalHistory = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    try {
      const response = await FetchMedicalHistory(agencyId, patientId);
      const data = response?.data?.data;
      // console.log(data, "get respo=======");
      if (data && Object.keys(data).length > 0) {
        setmedicalhistoryget(data);
        setMedicalData({
          head: data.head || {},
          eyes: data.eyes || {},
          ears: data.ears || {},
          noseSinuses: data.noseSinuses || {},
          mouthThroatTeeth: data.mouthThroatTeeth || {},
          cardiovascular: data.cardiovascular || {},
          respiratory: data.respiratory || {},
          neurological: data.neurological || {},
          musculoskeletal: data.musculoskeletal || {},
          skin: data.skin || {},
          endocrine: data.endocrine || {},
          infectiousDiseases: data.infectiousDiseases || {},
          hematologicOncologic: data.hematologicOncologic || {},
          psychiatric: data.psychiatric || {},
          genitourinary: data.genitourinary || {},
          gastrointestinal: data.gastrointestinal || {},
          immuneSystem: data.immuneSystem || {},
        });
      } else {
        console.log("No family history data found or data is empty.");
      }
    } catch (error) {
      console.log("Error fetching family history:", error);
    }
  };
  const MedicalHistoryUpdate = async () => {
    // const id = ;
    const data = {
      // id,
      ...medicalData,
    };
    // console.log(data, "submit datat======");
    try {
      const response = await MedicalHistoryonsubmitUpdate(
        medicalhistoryget?._id,
        data
      );
      // console.log(response?.data?.data, "update response======");
      ShowToast(response?.data?.message);
      GetMedicalHistory();
      closeModal()
    } catch (error) {
      console.error("Error submitting MedicalHistoryUpdate:", error);
    }
  };
  const handleMedicalCheckboxChange = (category, key, value) => {
    setMedicalData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: value,
      },
    }));
  };
  const handlePsychiatricHistoryCheckboxChange = (category, key, value) => {
    setPsychiatricHistoryData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: value,
      },
    }));
  };

  const PsychiatricHistorySave = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    const data = {
      agencyId,
      patientId,
      ...psychiatricHistoryData,
    };
    console.log(data, "save===data===>>>");
    // return
    try {
      const response = await PsychiatricHistoryonsubmit(data);
      console.log(
        response?.data?.data,
        "hii-=--PsychiatricHistoryonsubmit----"
      );
      // ShowToast(response?.data?.message);
      Getpsychiatrichistory();
      setispsychiatricHistory(false);
    } catch (error) {
      console.error("Error submitting PsychiatricHistoryonsubmit:", error);
    }
  };
  const Getpsychiatrichistory = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    try {
      const response = await FetchPsychiatricHistory(agencyId, patientId);
      const data = response?.data?.data;
      if (data && Object.keys(data).length > 0) {
        setpsychiatricdata(data);
        setPsychiatricHistoryData({
          psychiatricConditions: data.psychiatricConditions || {},
          neurocognitiveDisorders: data.neurocognitiveDisorders || {},
          developmentalDisorders: data.developmentalDisorders || {},
          symptoms: data.symptoms || {},
          mentalHealthTreatmentHistory: data.mentalHealthTreatmentHistory || {},
          substanceUse: data.substanceUse || {},
          familypaychiaticHistory: data.familypaychiaticHistory || {},
          socialEnvironmentalstressors: data.socialEnvironmentalstressors || {},
          cognitiveandBehavioralPatterns:
            data.cognitiveandBehavioralPatterns || {},
          sleepandEnergyLevels: data.sleepandEnergyLevels || {},
        });
      } else {
        // console.log("No family history data found or data is empty.");
      }
    } catch (error) {
      console.log("Error fetching family history:", error);
    }
  };
  const PsychiatricHistoryUpdate = async () => {
    // const id = ;

    const data = {
      // id,
      ...psychiatricHistoryData,
    };
    // console.log(data, "submit datat======");
    try {
      const response = await PsychiatricHistoryonsubmitUpdate(
        psychiatricdata?._id,
        data
      );
      // console.log(response?.data?.data, "update response======");
      ShowToast(response?.data?.message);
      Getpsychiatrichistory();
      setispsychiatricHistory(false);
    } catch (error) {
      console.error("Error submitting MedicalHistoryUpdate:", error);
    }
  };

  const LifestyleDeterminantsSave = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    const data = {
      agencyId,
      patientId,
      ...LifestyleDeterminants,
    };
    // console.log(data, "save===data===>>>");
    // return
    try {
      const response = await LifestyleDeterminantsnsubmit(data);
      console.log(
        response?.data?.data,
        "hii-=--LifestyleDeterminantsnsubmit----"
      );
      ShowToast(response?.data?.message);
      GetLifestyleDeterminants();
      setislifestyleDeterminants(false);
    } catch (error) {
      console.error("Error submitting LifestyleDeterminantsnsubmit:", error);
    }
  };
  const GetLifestyleDeterminants = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    try {
      const response = await FetchLifestyleDeterminants(agencyId, patientId);
      const data = response?.data?.data;
      // console.log(data,"======")
      // console.log(response?.data?.data,"FetchLifestyleDeterminantsdatat=====>>>>>>>")
      if (data && Object.keys(data).length > 0) {
        setLifestyleDeterminantsget(data);
        setLifestyleDeterminants({
          tobaccoUse: data.tobaccoUse || {},
          alcoholUse: data.alcoholUse || {},
          substanceUse: data.substanceUse || {},
          cardiovascularHealth: data.cardiovascularHealth || {},
          patternsHistorySubstanceUse: data.patternsHistorySubstanceUse || {},
          safetyPractices: data.safetyPractices || {},
          sexualActivity: data.sexualActivity || {},
          birthGender: data.birthGender || {},
        });
      } else {
        // console.log("No family history data found or data is empty.");
      }
    } catch (error) {
      console.log("Error fetching family history:", error);
    }
  };
  // console.log(LifestyleDeterminants,"LifestyleDeterminants")
  const LifestyleDeterminantsUpdate = async () => {
    // const id = ;

    const data = {
      // id,
      ...LifestyleDeterminants,
    };
    // console.log(data, "submit datat======");
    try {
      const response = await LifestyleDeterminantsonsubmitUpdate(
        LifestyleDeterminantsget?._id,
        data
      );
      // console.log(response?.data?.data, "update response======");
      ShowToast(response?.data?.message);
      GetLifestyleDeterminants();
      setislifestyleDeterminants(false);
    } catch (error) {
      console.error("Error submitting MedicalHistoryUpdate:", error);
    }
  };
  // console.log(medicalhistoryget,"medicalhistoryget====")
  const formatUpdatedAt = (dateString) => {
    if (!dateString) return "Never";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    }).format(date);
  };

  const getFirstTwoTrueValues = (data) => {
    const trueValues = [];
    for (const section in data) {
      if (typeof data[section] === "object") {
        for (const key in data[section]) {
          if (data[section][key] === true) {
            trueValues.push(key);
          }
        }
      }
    }
    return trueValues.slice(0, 5);
  };
  const getDisplayLabel = (key) => {
    for (const category of Medicalhistoycategories) {
      if (category.options[key]) {
        return category.options[key];
      }
    }
    return key;
  };
  const getDisplayFamilyLabel = (key) => {
    for (const category of FamilyHistorydata) {
      if (category.options[key]) {
        return category.options[key];
      }
    }
    return key;
  };
  const getDisplayPsychiatricHistorydataLabel = (key) => {
    for (const category of FamilyHistorydata) {
      if (category.options[key]) {
        return category.options[key];
      }
    }
    return key;
  };
  const getDisplayLifestyleDeterminantsLabel = (key) => {
    for (const category of PsychiatricHistorydata) {
      if (category.options[key]) {
        return category.options[key];
      }
    }
    return key;
  };
  const getsurgicalDisplayLabel = (key) => {
    for (const category of SurgicalHistorydata) {
      if (category.options[key]) {
        return category.options[key];
      }
    }
    return key;
  };
  const medicalhistoryValues = getFirstTwoTrueValues(medicalhistoryget);
  const displayValues = medicalhistoryValues.map(getDisplayLabel);
  const surgicalValues = getFirstTwoTrueValues(SurgicalHistoryget);
  const displaysurgicalValues = surgicalValues.map(getsurgicalDisplayLabel);
  // getsurgicalDisplayLabel
  const familyhistoryValues = getFirstTwoTrueValues(familyhistoryget);
  const displayfamilylabel = familyhistoryValues.map(getDisplayFamilyLabel);
  const psychiatricdataValues = getFirstTwoTrueValues(psychiatricdata);
  const PsychiatricHistorylabel = psychiatricdataValues.map(
    getDisplayPsychiatricHistorydataLabel
  );
  const LifestyleDeterminantsValues = getFirstTwoTrueValues(
    LifestyleDeterminantsget
  );
  const LifestyleDeterminantsLabel = LifestyleDeterminantsValues.map(
    getDisplayLifestyleDeterminantsLabel
  );
  const handlesurgicalCheckboxChange = (category, key, value) => {
    setSurgicalHistoryform((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [key]: value,
      },
    }));
  };

  const SurgicalHistorySave = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    const data = {
      agencyId,
      patientId,
      ...SurgicalHistoryform,
    };
    console.log(data, "subm,it surgical =====>>");
    // return
    try {
      const response = await SurgicalHistoryonsubmit(data);
      ShowToast(response?.data?.message);
      FetchSurgicalHistory();
      setismedicalHistory(false);
    } catch (error) {
      console.log(error);
    }
  };
  const FetchSurgicalHistory = async () => {
    const agencyId = info?.agencyId?._id;
    const patientId = info?.patientId?._id;
    // return
    try {
      const response = await GetSurgicalHistory(agencyId, patientId);
      const data = response?.data?.data;
      // console.log(data, "get respo=======");
      if (data && Object.keys(data).length > 0) {
        setSurgicalHistoryget(data);
        setSurgicalHistoryform({
          cardiovascularSurgeries: data.cardiovascularSurgeries || {},
          commonSurgeries: data.commonSurgeries || {},
          ophthalmologySurgeries: data.ophthalmologySurgeries || {},
          gynecologicalSurgeries: data.gynecologicalSurgeries || {},
          urologicalSurgeries: data.urologicalSurgeries || {},
          orthopedicSurgeries: data.orthopedicSurgeries || {},
          entSurgeries: data.entSurgeries || {},
          skinCancerSurgeries: data.skinCancerSurgeries || {},
          miscellaneousSurgeries: data.miscellaneousSurgeries || {},
        });
      } else {
        console.log("No family history data found or data is empty.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const UpdateSurgicalHistory = async () => {
    const data = {
      // id,
      ...SurgicalHistoryform,
    };
    console.log(data, "data======");
    try {
      const response = await SurgicalHistoryonsubmitUpdate(
        SurgicalHistoryget?._id,
        data
      );
      // console.log(response?.data, "========>>>>");
      setismedicalHistory(false);
      FetchSurgicalHistory();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
    className="container_NotesTabScreen"
     >
      <div className="history-sections_history">
        <div className="history-section_history_two">
          <div>
            <h3>
              Medical History
              <button
                className="open-modal-btn_history"
                onClick={() => openModal()}
              >
                Edit
              </button>
            </h3>
            <div style={{ textAlign: "left" }}>
              {displayValues.length > 0 ? (
                displayValues.map((value, index) => (
                  <div key={index}>{value}</div>
                ))
              ) : (
                <div>No issues reported</div>
              )}
            </div>
          </div>
          {medicalhistoryget?.updatedAt ? (
            <div>
              Last Updated: {formatUpdatedAt(medicalhistoryget?.updatedAt)}
            </div>
          ) : (
            <div> Last Updated: Never</div>
          )}
        </div>

        <div className="history-section_history_two">
          <div>
            <h3>
              Surgical History
              <button
                className="open-modal-btn_history"
                onClick={() => {
                  setismedicalHistory(true);
                }}
              >
                Edit
              </button>
            </h3>
            <div style={{ textAlign: "left" }}>
              {displaysurgicalValues.length > 0 ? (
                displaysurgicalValues.map((value, index) => (
                  <div key={index}>{value}</div>
                ))
              ) : (
                <div>No issues reported</div>
              )}
            </div>
          </div>
          {SurgicalHistoryget?.updatedAt ? (
            <div>
              Last Updated: {formatUpdatedAt(SurgicalHistoryget?.updatedAt)}
            </div>
          ) : (
            <div> Last Updated: Never</div>
          )}
        </div>
        <div className="history-section_history_two">
          <div>
            <h3>
              Family History
              <button
                className="open-modal-btn_history"
                onClick={() => {
                  setisfamilyHistory(true);
                }}
              >
                Edit
              </button>
            </h3>
            <div style={{ textAlign: "left" }}>
              {displayfamilylabel.length > 0 ? (
                displayfamilylabel?.map((value, index) => (
                  <div key={index}>{value}</div>
                ))
              ) : (
                <div>No issues reported</div>
              )}
            </div>
          </div>
          {familyhistoryget?.updatedAt ? (
            <div>
              Last Updated: {formatUpdatedAt(familyhistoryget?.updatedAt)}
            </div>
          ) : (
            <div> Last Updated: Never</div>
          )}
        </div>

        <div className="history-section_history_two">
          <div>
            <h3>
              Psychiatric History
              <button
                className="open-modal-btn_history"
                onClick={() => {
                  setispsychiatricHistory(true);
                }}
              >
                Edit
              </button>
            </h3>
            <div style={{ textAlign: "left" }}>
              {PsychiatricHistorylabel.length > 0 ? (
                PsychiatricHistorylabel.map((value, index) => (
                  <div key={index}>{value}</div>
                ))
              ) : (
                <div>No issues reported</div>
              )}
            </div>
          </div>
          {psychiatricdata?.updatedAt ? (
            <div>
              Last Updated: {formatUpdatedAt(psychiatricdata?.updatedAt)}
            </div>
          ) : (
            <div> Last Updated: Never</div>
          )}
        </div>
        <div className="history-section_history_two">
          <div>
            <h3>
              Lifestyle Determinants
              <button
                className="open-modal-btn_history"
                onClick={() => {
                  setislifestyleDeterminants(true);
                }}
              >
                Edit
              </button>
            </h3>
            <div style={{ textAlign: "left" }}>
              {LifestyleDeterminantsLabel.length > 0 ? (
                LifestyleDeterminantsLabel.map((value, index) => (
                  <div key={index}>{value}</div>
                ))
              ) : (
                <div>No issues reported</div>
              )}
            </div>
          </div>
          {LifestyleDeterminantsget?.updatedAt ? (
            <div>
              Last Updated:{" "}
              {formatUpdatedAt(LifestyleDeterminantsget?.updatedAt)}
            </div>
          ) : (
            <div> Last Updated: Never</div>
          )}
        </div>
      </div>

      {modalVisible && (
        <div
          className={`modal_history ${modalVisible ? "" : "modal_hidden"}`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Medical History"}</div>
              <button className="close-btn_history" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {Medicalhistoycategories.map(
                ({ category, label, options }, index) => (
                  <div className="category_history" key={index}>
                    <h4>{label}</h4>
                    {Object.entries(options).map(([key, optionLabel], i) => (
                      <label key={i}>
                        <input
                          type="checkbox"
                          checked={medicalData[category][key]}
                          onChange={(e) =>
                            handleMedicalCheckboxChange(
                              category,
                              key,
                              e.target.checked
                            )
                          }
                        />{" "}
                        {optionLabel}
                      </label>
                    ))}
                  </div>
                )
              )}
            </div>
            <div className="modal-footer_history">
              <button onClick={closeModal}>Cancel</button>
              {!medicalhistoryget?._id ? (
                <button onClick={MedicalHistoryonSave}>Save</button>
              ) : (
                <button onClick={MedicalHistoryUpdate}>Update</button>
              )}
            </div>
          </div>
        </div>
      )}
      {ismedicalHistory && (
        <div
          className={`modal_history ${ismedicalHistory ? "" : "modal_hidden"}`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Surgical History"}</div>
              <button
                className="close-btn_history"
                onClick={() => {
                  setismedicalHistory(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {SurgicalHistorydata.map(
                ({ category, label, options }, index) => (
                  <div className="category_history" key={index}>
                    <h4>{label}</h4>
                    {Object.entries(options).map(([key, optionLabel], i) => (
                      <label key={i}>
                        <input
                          type="checkbox"
                          checked={SurgicalHistoryform[category][key]}
                          onChange={(e) =>
                            handlesurgicalCheckboxChange(
                              category,
                              key,
                              e.target.checked
                            )
                          }
                        />{" "}
                        {optionLabel}
                      </label>
                    ))}
                  </div>
                )
              )}
            </div>
            <div className="modal-footer_history">
              <button
                onClick={() => {
                  setismedicalHistory(false);
                }}
              >
                Cancel
              </button>
              {/* <button
                onClick={SurgicalHistorySave}
              >
                Save
              </button> */}
              {!SurgicalHistoryget?._id ? (
                <button onClick={SurgicalHistorySave}>Save</button>
              ) : (
                <button onClick={UpdateSurgicalHistory}>Update</button>
              )}
            </div>
          </div>
        </div>
      )}
      {isfamilyHistory && (
        <div
          className={`modal_history ${isfamilyHistory ? "" : "modal_hidden"}`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Family History"}</div>
              <button
                className="close-btn_history"
                onClick={() => {
                  setisfamilyHistory(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {FamilyHistorydata.map(({ category, label, options }, index) => (
                <div className="category_history" key={index}>
                  <h4>{label}</h4>
                  {Object.entries(options).map(([key, optionLabel], i) => (
                    <label key={i}>
                      <input
                        type="checkbox"
                        checked={formData[category][key]}
                        onChange={(e) =>
                          handleCheckboxChange(category, key, e.target.checked)
                        }
                      />{" "}
                      {optionLabel}
                    </label>
                  ))}
                </div>
              ))}
            </div>
            <div className="modal-footer_history">
              <button
                onClick={() => {
                  setisfamilyHistory(false);
                }}
              >
                Cancel
              </button>
              {!familyhistoryget?._id ? (
                <button onClick={FamilyHistorysave}>Save</button>
              ) : (
                <button onClick={FamilyHistoryUpdate}>Update</button>
              )}
            </div>
          </div>
        </div>
      )}
      {ispsychiatricHistory && (
        <div
          className={`modal_history ${
            ispsychiatricHistory ? "" : "modal_hidden"
          }`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Psychiatric History"}</div>
              <button
                className="close-btn_history"
                onClick={() => {
                  setispsychiatricHistory(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {PsychiatricHistorydata.map(
                ({ category, label, options }, index) => (
                  <div className="category_history" key={index}>
                    <h4>{label}</h4>
                    {Object.entries(options).map(([key, optionLabel], i) => (
                      <label key={i}>
                        <input
                          type="checkbox"
                          checked={psychiatricHistoryData[category][key]}
                          onChange={(e) =>
                            handlePsychiatricHistoryCheckboxChange(
                              category,
                              key,
                              e.target.checked
                            )
                          }
                        />{" "}
                        {optionLabel}
                      </label>
                    ))}
                  </div>
                )
              )}
            </div>
            <div className="modal-footer_history">
              <button
                onClick={() => {
                  setispsychiatricHistory(false);
                }}
              >
                Cancel
              </button>
              {!psychiatricdata?._id ? (
                <button onClick={PsychiatricHistorySave}>Save</button>
              ) : (
                <button onClick={PsychiatricHistoryUpdate}>Update</button>
              )}
            </div>
          </div>
        </div>
      )}
      {islifestyleDeterminants && (
        <div
          className={`modal_history ${
            islifestyleDeterminants ? "" : "modal_hidden"
          }`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Drug Use History"}</div>
              <button
                className="close-btn_history"
                onClick={() => {
                  setislifestyleDeterminants(false);
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {[
                {
                  category: "tobaccoUse",
                  label: "Tobacco Use",
                  options: {
                    currentEverydaySmoker: "Current every day smoker",
                    currentSomeDaySmoker: "Current some day smoker",
                    formerSmoker: "Former smoker",
                    heavyTobaccoSmoker: "Heavy tobacco smoker",
                    lightTobaccoSmoker: "Light tobacco smoker",
                    neverSmoker: "Never smoker",
                    smokerCurrentStatusUnknown:
                      "Smoker, current status unknown",
                    unknownIfEverSmoked: "Unknown if ever smoked",
                  },
                },
                {
                  category: "alcoholUse",
                  label: "Alcohol Use",
                  options: {
                    doNotDrink: "Do not drink",
                    drinkDaily: "Drink daily",
                    frequentlyDrink: "Frequently drink",
                    historyOfAlcoholism: "History of Alcoholism",
                    occasionalDrink: "Occasional drink",
                  },
                },
                {
                  category: "substanceUse",
                  label: "Substance Use",
                  options: {
                    ivdu: "IVDU (Intravenous Drug Use)",
                    illicitDrugUse: "Illicit drug use",
                    noIllicitDrugUse: "No illicit drug use",
                    prescriptionDrugMisuse: "Prescription drug misuse",
                    marijuanaUse: "Marijuana use",
                    cocaineUse: "Cocaine use",
                    heroinUse: "Heroin use",
                    methamphetamineUse: "Methamphetamine use",
                    hallucinogenUse: "Hallucinogen use (LSD/MDMA/Psilocybin)",
                    inhalantUse: "Inhalant use (Glue, Paint Thinner)",
                    stimulantUse: "Stimulant use (e.g., Adderall, Ritalin)",
                    sedativeUse: "Sedative (Benzodiazepines)",
                    opioidUse: "Opioid use (Morph/Oxycod/Fentanyl)",
                  },
                },
                {
                  category: "cardiovascularHealth",
                  label: "Cardiovascular Health",
                  options: {
                    eatHealthyMeals: "Eat healthy meals",
                    regularExercise: "Regular exercise",
                    takeDailyAspirin: "Take daily aspirin",
                  },
                },
                {
                  category: "patternsHistorySubstanceUse",
                  label: "Patterns and History of Substance Use",
                  options: {
                    polysubstanceUse: "Polysubstance use (multiple)",
                    hxOfSubstanceRehab: "Hx of substance rehab programs",
                    hxOfWithdrawalSymptoms: "Hx of withdrawal symptoms",
                    affectingDailyLife: "Affecting daily life",
                    impactingRelationships: "Impacting relationships",
                    impactingEmployment: "Impacting employment",
                    other: "Other",
                  },
                },
                {
                  category: "safetyPractices",
                  label: "Safety Practices",
                  options: {
                    householdSmokeDetector: "Household Smoke Detector",
                    keepFirearmsInHome: "Keep firearms in home",
                    wearSeatbelts: "Wear seatbelts",
                  },
                },
                {
                  category: "sexualActivity",
                  label: "Sexual Activity",
                  options: {
                    exposureToSTI: "Exposure to STI",
                    nonHeterosexualActivity: "Non-Heterosexual activity",
                    notSexuallyActive: "Not sexually active",
                    safeSexPractices: "Safe sex practices",
                    sexuallyActive: "Sexually active",
                  },
                },
                {
                  category: "birthGender",
                  label: "Birth Gender",
                  options: {
                    male: "Male",
                    female: "Female",
                    undifferentiated: "Undifferentiated",
                  },
                },
              ].map(({ category, label, options }, index) => (
                <div className="category_history" key={index}>
                  <h4>{label}</h4>
                  {Object.entries(options).map(([key, optionLabel], i) => (
                    <label key={i}>
                      <input
                        type="checkbox"
                        checked={LifestyleDeterminants[category][key]}
                        onChange={(e) =>
                          handleCheckboxLifestyleDeterminantsChange(
                            category,
                            key,
                            e.target.checked
                          )
                        }
                      />{" "}
                      {optionLabel}
                    </label>
                  ))}
                </div>
              ))}
            </div>
            <div className="modal-footer_history">
              <button
                onClick={() => {
                  setislifestyleDeterminants(false);
                }}
              >
                Cancel
              </button>

              {!LifestyleDeterminantsget?._id ? (
                <button onClick={LifestyleDeterminantsSave}>Save</button>
              ) : (
                <button onClick={LifestyleDeterminantsUpdate}>Update</button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientHistory;

// import { View, Text } from 'react-native'
// import React from 'react'

// const ClientSubscriptionPlan = () => {
//   return (
//     <View>
//       <Text>ClientSubscriptionPlan</Text>
//     </View>
//   )
// }

// export default ClientSubscriptionPlan
import React from 'react'

function ClientSubscriptionPlan() {
  return (
    <>
    {/* Subscription Plans Section */}
    {/* <div id="subscription-section">
      <h3>Subscription Plans</h3>
      <div className="enroll-item">
        <div className="plan-name">Basic Plan</div>
        <div className="plan-description">
          Bi-weekly one-on-one therapy sessions, 24/7 crisis support, wellness
          programs.
        </div>
        <div className="plan-price">$149/month</div>
        <button className="button" onclick="enrollInPlan('Basic Plan')">
          Enroll Now
        </button>
        <button className="button" onclick="showPlanDetails('Basic Plan')">
          View Details
        </button>
      </div>
      <div className="enroll-item">
        <div className="plan-name">Advanced Plan</div>
        <div className="plan-description">
          Everything in Basic plus access to exclusive webinars and fitness
          programs.
        </div>
        <div className="plan-price">$199/month</div>
        <button className="button" onclick="enrollInPlan('Advanced Plan')">
          Enroll Now
        </button>
        <button className="button" onclick="showPlanDetails('Advanced Plan')">
          View Details
        </button>
      </div>
      <div className="enroll-item">
        <div className="plan-name">Ultimate Plan</div>
        <div className="plan-description">
          Everything in Advanced plus access to Lifestyle Medicine.
        </div>
        <div className="plan-price">$299/month</div>
        <button className="button" onclick="enrollInPlan('Ultimate Plan')">
          Enroll Now
        </button>
        <button className="button" onclick="showPlanDetails('Ultimate Plan')">
          View Details
        </button>
      </div>
    </div> */}
  <div className="container_subscription">
      <h1>Available Plans</h1>
      <div className="plan-card_subscription">
        <div className="plan-title_subscription">Basic Plan</div>
        <div className="plan-specialty_subscription">Specialty: Psychiatry</div>
        <div className="plan-details_subscription">
          <span><strong>Fee:</strong> $149</span>
          <span><strong>Frequency:</strong> Biweekly</span>
          <span><strong>Status:</strong> Active</span>
        </div>
        <button
          className="button_subscription details-button_subscription"
        >
          View Details
        </button>
        <button className="button_subscription enroll-button_subscription">Enroll Now</button>
      </div>
      <div className="plan-card_subscription">
        <div className="plan-title_subscription">Advanced Plan        </div>
        <div className="plan-specialty_subscription">Specialty: Psychiatry</div>
        <div className="plan-details_subscription">
          <span><strong>Fee:</strong> $199</span>
          <span><strong>Frequency:</strong> Monthly</span>
          <span><strong>Status:</strong> Active</span>
        </div>
        <button
          className="button_subscription details-button_subscription"
        >
          View Details
        </button>
        <button className="button_subscription enroll-button_subscription">Enroll Now</button>
      </div>
      <div className="plan-card_subscription">
        <div className="plan-title_subscription">Ultimate Plan</div>
        <div className="plan-specialty_subscription">Specialty: Psychiatry</div>
        <div className="plan-details_subscription">
          <span><strong>Fee:</strong> $299</span>
          <span><strong>Frequency:</strong> Annually</span>
          <span><strong>Status:</strong> Active</span>
        </div>
        <button
          className="button_subscription details-button_subscription"
        >
          View Details
        </button>
        <button className="button_subscription enroll-button_subscription">Enroll Now</button>
      </div>

</div>
  
  </>
  
  )
}

export default ClientSubscriptionPlan
import React, { useEffect, useState } from "react";
import Inputbox from "../../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Patientrequestupdates } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import PatientInsurence from "./PatientInsurence";
import PatinetPrivatePay from "./PatinetPrivatePay";
import PatinetSubscriptionPlan from "./PatinetSubscriptionPlan";
import Membershipcard from "./Membershipcard";
import MemberPlan from "./MemberPlan";

const validationSchema = Yup.object().shape({
  CardDetails: Yup.string().required("Card Details is required"),
  PaymentMethod: Yup.string().required("Payment Method is required"),
});
const ClientPayment = ({ info, getDteails }) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
// console.log(info,"info=====")
  const [Insurance, setInsurance] = useState(true);
  const [PrivatePay, setPrivatePay] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(false);
  const [membershipcard, setmembershipcard] = useState(false)

  useEffect(() => {
    if (info) {
      setValue("PaymentMethod", info?.PaymentMethod?.PaymentMethod || "");
      setValue("CardDetails", info?.PaymentMethod?.CardDetails || "");
    }
  }, [info, setValue]);


  const handlePaymentMethod = (method) => {
    setInsurance(method === "insurance");
    setPrivatePay(method === "privatePay");
    // setSubscriptionPlan(method === "subscriptionPlan");
    setmembershipcard(method === "membershipcard")
  };

  return (
    <div className="container_NotesTabScreen">
      <h1>Select Payment Method</h1>
      {/* <h2 className="notifications__title">Select Payment Method</h2> */}
      <div className="tabs-container__three" style={{ display: "flex", width: "100%", justifyContent: "left" }} />

      <p className="payment-description">
        Choose a method for payment. You can bill your insurance, pay privately,
        or subscribe to a plan for additional benefits.
      </p>
      <div className="payment-section">
        {/* Insurance Payment */}
        <div
          className={`payment-method ${Insurance ? "active" : ""}`}
          onClick={() => handlePaymentMethod("insurance")}
        >
          <input
            type="radio"
            id="insurance"
            name="payment_type"
            value="insurance"
            checked={Insurance}
            readOnly
          />
          <label >Bill the Client's Insurance</label>
        </div>

        <div
          className={`payment-method ${PrivatePay ? "active" : ""}`}
          onClick={() => handlePaymentMethod("privatePay")}
        >
          <input
            type="radio"
            id="private_pay"
            name="payment_type"
            value="private_pay"
            checked={PrivatePay}
            readOnly
          />
          <label htmlFor="private_pay">Private Pay (Out-of-Pocket)</label>
        </div>

        {/* <div
          className={`payment-method ${SubscriptionPlan ? "active" : ""}`}
          onClick={() => handlePaymentMethod("subscriptionPlan")}
        >
          <input
            type="radio"
            id="subscription"
            name="payment_type"
            value="subscription"
            checked={SubscriptionPlan}
            readOnly
          />
          <label htmlFor="subscription">Subscription Plan</label>
        </div> */}
        <div
          className={`payment-method ${membershipcard ? "active" : ""}`}
          onClick={() => handlePaymentMethod("membershipcard")}
        >
          <input
            type="radio"
            id="subscription"
            name="payment_type"
            value="subscription"
            checked={membershipcard}
            readOnly
          />
          <label htmlFor="subscription">Subscription Plan </label>
        </div>
      </div>
      {/* Membership Card */}

      {Insurance && <PatientInsurence  info={info}/>}
      {PrivatePay && <PatinetPrivatePay info={info} getDteails={getDteails} />}
      {/*  {SubscriptionPlan && <PatinetSubscriptionPlan />}*/}
      {/* {membershipcard &&  <Membershipcard/>} */}
      {membershipcard &&  <MemberPlan info={info} getDteails={getDteails}/>} 

    </div>
  );
};

export default ClientPayment;

import React, { useEffect, useState } from "react";
import Inputbox from "../../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import {
  ClientagencysideAddressremove,
  ClientagencysideAddressUpdate,
  Patientrequestupdates,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("Zip code is required"),
  }),
});
const Addresses = ({ info, getDteails }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    reset();
    setShowModal(false);
  };

  // console.log(info, "info=======");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM",
    libraries: ["places"],
  });
  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;

    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue(
      "address.state",
      getAddressComponent("administrative_area_level_1")
    );
    setValue("address.city", getAddressComponent("locality"));

    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    let fullStreet = "";

    if (establishment) {
      fullStreet = establishment;
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`;
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`;
    } else if (sublocality) {
      fullStreet = sublocality;
    } else if (neighborhood) {
      fullStreet = neighborhood;
    }

    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
        "administrative_area_level_1"
      )}, ${getAddressComponent("country")}`;
    }

    // Set the full street address in the form
    setValue("address.street", fullStreet);

    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };
  // console.log(info?.patientId, "info=-=-=-=-=");
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // const onSubmit = async (data) => {
  // data.id = info?._id;
  // const formattedData = {
  //   patientAddress: {
  //     street: data.address.street,
  //     city: data.address.city,
  //     state: data.address.state,
  //     country: data.address.country,
  //     zipCode: data.address.zipCode,
  //   },
  //   id: data.id,
  // };
  //   console.log(formattedData, "vformattedData====");
  //   // return
  //   try {
  //     const response = await Patientrequestupdates(formattedData);
  //     ShowToast(response?.data?.message);
  //     getDteails();
  //   } catch (error) {
  //     // Handle error
  //   }
  // };
  const onSubmit = async (data) => {
    data.id = info?._id;
    const formattedData = {
      patientAddress: {
        street: data.address.street,
        city: data.address.city,
        state: data.address.state,
        country: data.address.country,
        zipCode: data.address.zipCode,
      },
      id: data.id,
    };
    // console.log(formattedData, "vformattedData====");
    // return
    try {
      const response = await ClientagencysideAddressUpdate(formattedData);
      ShowToast(response?.data?.message);
      // console.log(response?.data?.data, "response---helloo-=====");
      getDteails();
      reset();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveAddress = async (id) => {
    console.log(`Removing address with id: ${id}`);
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this address?"
    );
    if (!isConfirmed) {
      console.log("Address removal was canceled.");
      return; // Exit if the user cancels the operation
    }

    const requestData = {
      patientRequestId: info?._id,
      addressId: id,
    };
    // console.log(requestData, "requestdata======");
    // return
    try {
      const response = await ClientagencysideAddressremove(requestData);
      ShowToast(response?.data?.message);
      getDteails();
    } catch (error) {
      console.log(error, "error====");
    }
  };
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div className="container_NotesTabScreen">
      <h1>Addresses</h1>
      <div className="tabs-container__three" style={{ display: "flex", width: "100%", justifyContent: "left" }} />
      
      {info?.patientAddresses?.length > 0 ? (
        info.patientAddresses.map((address) => (
          <div
            key={address._id}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "14%" }}
            >
              <div style={{ textAlign: "left" }}>Street:</div>
              <strong style={{ textAlign: "left" }}> {address.street}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "14%" }}
            >
              <div style={{ textAlign: "left" }}>City:</div>
              <strong style={{ textAlign: "left" }}>{address.city}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "14%" }}
            >
              <div style={{ textAlign: "left" }}>State:</div>
              <strong style={{ textAlign: "left" }}>{address.state}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "14%" }}
            >
              <div style={{ textAlign: "left" }}>Country:</div>
              <strong style={{ textAlign: "left" }}> {address.country}</strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "14%" }}
            >
              <div style={{ textAlign: "left" }}>Zip Code:</div>
              <strong style={{ textAlign: "left" }}>{address.zipCode}</strong>
            </div>
            <button
              style={{
                padding: "5px 10px",
                backgroundColor: "#d9534f",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                height: 50,
              }}
              onClick={() => handleRemoveAddress(address._id)}
            >
              Remove
            </button>
          </div>
        ))
      ) : (
        <p>No addresses available.</p>
      )}

      <button
        className="client-history__button client-history__button--save"
        style={{ alignSelf: "flex-start", display: "flex", marginTop: 5 }}
        onClick={openModal}
      >
        +Add More Addresses
      </button>
      {showModal && (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={modalStyle}
            className="form-container_twoo"
          >
            <h1 className="form-container_twoo_h1">Add Address</h1>
            <div>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  onLoad={(autocompleteInstance) =>
                    setAutocomplete(autocompleteInstance)
                  }
                  onPlaceChanged={handlePlaceSelect}
                >
                  <Inputbox
                    label="Street"
                    type="text"
                    placeholder="Enter Street"
                    name="address.street"
                    register={register}
                  />
                </Autocomplete>
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="City"
                  type="text"
                  placeholder="Enter City"
                  name="address.city"
                  register={register}
                />
                {errors.address?.city && (
                  <div className="errorMessage">
                    {errors.address.city.message}
                  </div>
                )}
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="State"
                  type="text"
                  placeholder="Enter State"
                  name="address.state"
                  register={register}
                />
                {errors.address?.state && (
                  <div className="errorMessage">
                    {errors.address.state.message}
                  </div>
                )}
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="ZIP Code"
                  type="text"
                  placeholder="Enter ZIP Code"
                  name="address.zipCode"
                  register={register}
                />
                {errors.address?.zipCode && (
                  <div className="errorMessage">
                    {errors.address.zipCode.message}
                  </div>
                )}
              </div>

              <div className="button-group" style={{ marginTop: 20 }}>
                <button type="button" className="btn cancel" onClick={closeModal}>
                  Cancel
                </button>
                <button type="submit" className="btn save">
                  Save Changes
                </button>
              </div>
            </div>
          </form>

          {/* <form onSubmit={handleSubmit(onSubmit)} style={modalStyle}>
            <h1 style={{ textAlign: "left" }}> Add Address</h1>
            <div>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  onLoad={(autocompleteInstance) =>
                    setAutocomplete(autocompleteInstance)
                  }
                  onPlaceChanged={handlePlaceSelect}
                >
                  <Inputbox
                    label="Street"
                    type="text"
                    placeholder="Enter Street"
                    name="address.street"
                    register={register}
                  />
                </Autocomplete>
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="City"
                  type="text"
                  placeholder="Enter City"
                  name="address.city"
                  register={register}
                />
                {errors.address?.city && (
                  <div className="errorMessage">
                    {errors.address.city.message}
                  </div>
                )}
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="State"
                  type="text"
                  placeholder="Enter State"
                  name="address.state"
                  register={register}
                />
                {errors.address?.state && (
                  <div className="errorMessage">
                    {errors.address.state.message}
                  </div>
                )}
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="ZIP Code"
                  type="text"
                  placeholder="Enter ZIP Code"
                  name="address.zipCode"
                  register={register}
                />
                {errors.address?.zipCode && (
                  <div className="errorMessage">
                    {errors.address.zipCode.message}
                  </div>
                )}
              </div>
            </div>

            <div className="client-history__buttons" style={{ marginTop: 20 }}>
              <button
                className="client-history__button client-history__button--cancel"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="client-history__button client-history__button--save"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </form> */}
        </>
      )}
    </div>
  );
};

export default Addresses;
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",


  boxShadow: 24,
  p: 4,
  borderRadius: 10,
  outline: "none",
  overflowY: "auto",
  backgroundColor: "white",
  border: "1px solid grey",

};

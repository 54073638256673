import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AddpatientAllergy,
  DeletepatientAllergy,
  GetpatientAllergy,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  Allergy: Yup.string().required("Allergy is required"),
  Code: Yup.string().required("Code is required"),
  Date: Yup.date().nullable().required("Recorded Date is required"),
  ClinicalStatus: Yup.string().required("Clinical Status is required"),
  VerificationStatus: Yup.string().required("Verification Status is required"),
  Criticality: Yup.string().required("Criticality is required"),
  LastReactionDate: Yup.date().required("Last Reaction Date is required"),
  Kind: Yup.string().required("Reaction Kind is required"),
  Severity: Yup.string().required("Reaction Severity is required"),
  ManagementInstructions: Yup.string()
    .required("Management Instructions is required")
    .max(500, "Management measures must be less than 500 characters"),
});
const AddAllergy = ({ info }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [ismodal, setIsModal] = useState(false);
  const [allergydata, setallergydata] = useState([]);
  const profileData = useSelector((state) => state?.cookies?.userDetails);

  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    reset();
    setIsModal(false);
  };

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      patientId: info?.patientId?._id,
      agencyId: profileData?._id,
    };
    // console.log(payload, "data====");
    // return;
    try {
      const respo = await AddpatientAllergy(payload);
      // console.log(respo?.data?.data,"resp======");
      ShowToast(respo?.data?.message);
      Getdata();
      CloseModal();
    } catch (error) {
      console.log(error);
    }
  };
  const Getdata = async () => {
    try {
      const respo = await GetpatientAllergy(info?.patientId?._id);
      // console.log(respo?.data?.data?.data,"respo======")
      setallergydata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getdata();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Allergy?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await DeletepatientAllergy(id);
      ShowToast(respo?.data?.message);
      Getdata();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>Allergies</h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add Allergy
        </button>
        <div
          className="tabs-container__three"
          style={{ display: "flex", width: "100%", justifyContent: "left" }}
        />
        {ismodal && (
          <div className="modal_AddAllergy">
            <h2>Add Allergy</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row_AddAllergy">
                <div className="form-group_AddAllergy">
                  <label htmlFor="Allergy">Allergy</label>
                  <input
                    type="text"
                    id="Allergy"
                    placeholder="Search or enter Allergy"
                    list="allergyList"
                    {...register("Allergy")}
                  />
                  <datalist id="allergyList">
                    <option value="Peanuts" />
                    <option value="Shellfish" />
                    <option value="Pollen" />
                    <option value="Dust" />
                    <option value="Latex" />
                  </datalist>
                  <div className="error">{errors.Allergy?.message}</div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="Code">Code</label>
                  <input
                    type="text"
                    id="Code"
                    placeholder="Enter Code"
                    {...register("Code")}
                  />
                  <div className="error">{errors.Code?.message}</div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="Date">Recorded Date</label>
                  <input type="date" id="Date" {...register("Date")} />
                  <div className="error">{errors.Date?.message}</div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="ClinicalStatus">Clinical Status</label>
                  <select id="ClinicalStatus" {...register("ClinicalStatus")}>
                    <option value="">Select clinical status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <div className="error">{errors.ClinicalStatus?.message}</div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="VerificationStatus">
                    Verification Status
                  </label>
                  <select
                    id="VerificationStatus"
                    {...register("VerificationStatus")}
                  >
                    <option value="">Select verification status</option>
                    <option value="Unconfirmed">Unconfirmed</option>
                    <option value="Confirmed">Confirmed</option>
                  </select>
                  <div className="error">
                    {errors.VerificationStatus?.message}
                  </div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="Criticality">Criticality</label>
                  <select id="Criticality" {...register("Criticality")}>
                    <option value="">Select Criticality</option>
                    <option value="Low">Low</option>
                    <option value="High">High</option>
                  </select>
                  <div className="error">{errors.Criticality?.message}</div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="LastReactionDate">Last Reaction Date</label>
                  <input
                    type="date"
                    id="LastReactionDate"
                    {...register("LastReactionDate")}
                  />
                  <div className="error">
                    {errors.LastReactionDate?.message}
                  </div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="Kind">Reaction Kind</label>
                  <input
                    type="text"
                    id="Kind"
                    placeholder="Enter reaction kind"
                    {...register("Kind")}
                  />
                  <div className="error">{errors.Kind?.message}</div>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="Severity">Reaction Severity</label>
                  <select id="Severity" {...register("Severity")}>
                    <option value="">Select severity</option>
                    <option value="Mild">Mild</option>
                    <option value="Moderate">Moderate</option>
                    <option value="Severe">Severe</option>
                  </select>
                  <div className="error">{errors.Severity?.message}</div>
                </div>

                <div
                  className="form-group_AddAllergy"
                  style={{ flex: "1 1 100%" }}
                >
                  <label htmlFor="ManagementInstructions">
                    Management Measures
                  </label>
                  <textarea
                    id="ManagementInstructions"
                    placeholder="Enter management measures"
                    rows={2}
                    {...register("ManagementInstructions")}
                  />
                  <div className="error">
                    {errors.ManagementInstructions?.message}
                  </div>
                </div>
              </div>

              <div className="modal-footer_AddAllergy">
                <button
                  type="button"
                  className="cancel-button_AddAllergy"
                  onClick={CloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button_AddAllergy"
                  style={{ marginLeft: 10 }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="notes_style">
          <table>
            <thead>
              <tr>
                <th>Allergy</th>
                <th>Code</th>
                <th>Recorded Date</th>
                <th>Clinical Status</th>
                <th>Verification Status</th>
                <th>Criticality</th>
                <th>Last Reaction Date</th>
                <th>Reaction Kind</th>
                <th>Reaction Severity</th>
                <th>Management Measures</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allergydata.length === 0 ? (
                <tr>
                  <td colSpan="10">No data found</td>
                </tr>
              ) : (
                allergydata.map((item, index) => (
                  <tr key={item._id || index}>
                    <td>{item.Allergy}</td>
                    <td>{item.Code}</td>
                    <td>{new Date(item.Date).toLocaleDateString()}</td>
                    <td>{item.ClinicalStatus}</td>
                    <td>{item.VerificationStatus}</td>
                    <td>{item.Criticality}</td>
                    <td>
                      {new Date(item.LastReactionDate).toLocaleDateString()}
                    </td>
                    <td>{item.Kind}</td>
                    <td>{item.Severity}</td>
                    <td>{item.ManagementInstructions}</td>
                    <td>
                      <button
                        className="btn"
                        style={{ backgroundColor: 'red',color:'white' }}
                        onClick={() => handleRemove(item._id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddAllergy;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetconsentManagement } from "../../../../api/helper";

const Consents = ({ info }) => {
  const [consentdata, setconsentdata] = useState([]);
  const [ismodal, setIsModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState(null);

  const openModal = (item) => {
    setSelectedConsent(item);
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
    setSelectedConsent(null);
  };
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const FetchConsentManagement = async () => {
    try {
      const respo = await GetconsentManagement(profiledata?._id);
      console.log(respo?.data?.data?.data, "dataget========");
      setconsentdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchConsentManagement();
  }, []);
  console.log(selectedConsent, "selectedConsent====");
  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>Consents</h1>
        <table>
          <thead>
            <tr>
              <th>Consent Title</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {consentdata.length === 0 ? (
              <tr>
                <td colSpan="10">No data found</td>
              </tr>
            ) : (
              consentdata.map((item, index) => (
                <tr key={item._id || index}>
                  <td>{item.Title}</td>
                  <td
                    className={
                      item.status === "active" ? "status-signed" : "status-sent"
                    }
                  >
                    {item.status}
                  </td>

                  <td>
                    <button
                      style={{
                        width: 100,
                      }}
                      className="view-btn"
                      onClick={() => openModal(item)}
                    >
                      View
                    </button>
                    {item.status === "active" && (
                      <button
                        className="download-btn"
                        style={{ marginLeft: 10 }}
                      >
                        Download PDF
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {ismodal && selectedConsent && (
          <div className="modal_PatientDocuments">
            <div>
              <div
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  width: 50,
                  position: "absolute",
                  right: 18,
                  top: 27,
                }}
              >
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
              </div>
              <h3 style={{ textAlign: "left", marginTop: 20 }}>
                {selectedConsent.Title}
              </h3>
              <p style={{ textAlign: "left" }}>
                <strong>Category:</strong> {selectedConsent.Category}
              </p>

              <p style={{ textAlign: "left" }}>
                <strong>Client Name:</strong>{" "}
                {info?.patientId?.firstName + " " + info.patientId?.lastName}
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>Agency Name:</strong>{" "}
                {selectedConsent.agencyId?.firstName +
                  " " +
                  selectedConsent.agencyId?.lastName}
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>Signed Date:</strong> {selectedConsent.SignedDate}
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>Consent Details:</strong>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: selectedConsent.Content }}
                />
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>Signature:</strong> {selectedConsent.Signature}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Consents;

import React, { useEffect, useState } from 'react'
import { ShowToast } from '../../../helpers/ToastService';
import { FetchQuestionnaireList, UploadQuestionnaireList } from '../../../api/helper';
import { Trash2 } from "lucide-react";

const AddNewQuestionnaire = () => {
    const [loading, setLoading] = useState(false);
      const [questionnaires, setquestionnaires] = useState([]);
    
    const [form, setForm] = useState({
      id: "",
      label: "",
      description: "",
    });
      const [ismodal, setIsModal] = useState(false);
      const openModal = () => {
        setIsModal(true);
      };
      const CloseModal = () => {
        setIsModal(false);
      };
    const GetQuestionnairelist = async () => {
        try {
          const response = await FetchQuestionnaireList();
          setquestionnaires(response?.data?.questionnaires);
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        GetQuestionnairelist();
      }, []);
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await UploadQuestionnaireList(form)
        GetQuestionnairelist()
        CloseModal()
        if (response.status === 201) {
          ShowToast("Questionnaire created successfully");
       
        }
      } catch (error) {
        CloseModal()
        console.log(error);
        ShowToast(
          error?.response?.data?.message ||
            "Something went wrong ! Please check console"
        );
        setLoading(false);
        CloseModal()
      }
    };
  return (
    <>
    {ismodal &&
      <div className="questionnaire-container">
      <h1 className="questionnaire-title">Add New Questionnaire</h1>
    
      <form onSubmit={handleSubmit} className="questionnaire-form">
        <div className="questionnaire-form-group">
          <label htmlFor="label" className="questionnaire-label">
            Questionnaire Title
          </label>
          <input
            type="text"
            name="label"
            required
            className="questionnaire-input"
            onChange={(e) => setForm({ ...form, label: e.target.value })}
          />
        </div>
    
        <div className="questionnaire-form-group">
          <label htmlFor="id" className="questionnaire-label">
            Questionnaire Abbreviation
          </label>
          <input
            type="text"
            name="id"
            required
            className="questionnaire-input"
            onChange={(e) => setForm({ ...form, id: e.target.value })}
          />
        </div>
    
        <div className="questionnaire-form-group">
          <label htmlFor="description" className="questionnaire-label">
            Description
          </label>
          <textarea
            type="text"
            name="description"
            className="questionnaire-textarea"
            onChange={(e) => setForm({ ...form, description: e.target.value })}
          ></textarea>
        </div>
    
        <button type="submit" disabled={loading} className="questionnaire-submit-btn">
          {loading ? "Questionnaire..." : "Create Questionnaire"}
        </button>
      </form>
    </div>
    }
  
  <div style={{alignItems:'center',display:'flex',justifyContent:'space-between'}}>
  <h1>Questionnaire</h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add Questionnaire
        </button>
  </div>
   <div className="questionnaire-list-container">
   <h1 className="questionnaire-list-title">Questionnaire List</h1>
   <ul className="questionnaire-list">
     {questionnaires?.map((item) => (
       <li key={item?.id} className="questionnaire-list-item">
         {item?.label}
         <Trash2
           className={`questionnaire-delete-icon ${
             item?.isPreData ? "disabled-icon" : "active-icon"
           }`}
         />
       </li>
     ))}
   </ul>
 </div>
 </>
  )
}

export default AddNewQuestionnaire
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// Validation Schema
const validationSchema = Yup.object().shape({
  clientID: Yup.string().required("Client ID is required"),
  fullName: Yup.string().required("Full Name is required"),
  dob: Yup.date().required("Date of Birth is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const VerifyIdentity = ({ onClose, onSuccess}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    console.log("Form Data:", data); // Replace with API call
    onSuccess();
  };

  return (
    <div className="modal_PatientDocuments">
      <div className="container_VerifyIdentity">
      <div
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    width: 50,
                    position: "absolute",
                    right: 18,
                    top: 27,
                  }}
                  onClick={onClose}
                >
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                    style={{ height: 20, width: 20 }}
                  />
                </div>
        <h2>Verify Your Identity</h2>
        <p>Please enter your details to continue.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="clientID">Client ID</label>
          <input
            type="text"
            id="clientID"
            placeholder="Enter your Client ID"
            {...register("clientID")}
          />
          {errors.clientID && <p className="error"> {errors.clientID.message}</p>}

          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            placeholder="Enter your full name"
            {...register("fullName")}
          />
          {errors.fullName && <p className="error">{errors.fullName.message}</p>}

          <label htmlFor="dob">Date of Birth</label>
          <input type="date" id="dob" {...register("dob")} />
          {errors.dob && <p className="error">{errors.dob.message}</p>}

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            {...register("email")}
          />
          {errors.email && <p className="error">{errors.email.message}</p>}

          <button type="submit" className="cta-button">
            Verify Identity
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyIdentity;

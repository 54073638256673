

import React, { useEffect, useState } from 'react';

import { ClientgetTeam, PatientDeleteTeam, PatientgetTeam } from '../../../../api/helper';
import { ShowToast } from '../../../../helpers/ToastService';
import Clientinternal from './Clientinternal';
import ClientExternal from './ClientExternal';
import ClientFacility from './ClientFacility';

const CareTeam = ({ info }) => {
  const [activeTab, setActiveTab] = useState("internal");
  const [ismodal, setismodal] = useState(false)
  const [members, setMembers] = useState(null);

  // console.log(members,"members------")

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const openModal = () => {
    setismodal(true)
  };

  const closeModal = () => {
    setismodal(false)
  };

  const Fetchteammember = async () => {
    try {
      const response = await ClientgetTeam(info?._id);
      setMembers(response?.data?.data?.TeamMember)
      // console.log(response?.data?.data?.TeamMember, "get======PatientgetTeam===");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Fetchteammember();
  }, []);
  const handleRemove = async (id) => {
    //  console.log(id)
    const confirmed = window.confirm("Are you sure you want to delete this Team Member?");
    if (!confirmed) {
      return;
    }
    try {
      const response = await PatientDeleteTeam(id);
      ShowToast(response?.data?.message);
      Fetchteammember()
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container_NotesTabScreen">
      <h1>Care Team</h1>
      <button
        className="add-button_NotesTabScreen"
        style={{ float: "right" }}
        onClick={openModal}
      >
        Add Member
      </button>

      <div className="tabs-container__three" style={{ display: "flex", width: "100%", justifyContent: "left" }} />

      <table className="table" id="member-table">
        <thead>
          <tr>
            <th>Facility</th>
            <th>Provider Type</th>
            <th>Team Member</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {members && members.length > 0 ? (
            members.map((member) => (
              <tr key={member._id}>
                <td>{member.facility || member?.practice || '-'}</td>
                <td>{member.providerType || '-'}</td>
                <td>{member.teamMember || '-'}</td>
                <td>{new Date(member.fromDate).toLocaleDateString()}</td>
                <td>{new Date(member.toDate).toLocaleDateString()}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleRemove(member._id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                No Team Member found
              </td>
            </tr>
          )}
        </tbody>
      </table>


      {ismodal && <>

        <div className="modal_twooo" id="add-member-modal">
          <h2>Add Team Member</h2>
          <div className="tab-buttons_twoooo">
            <button
              className={activeTab === "internal" ? "active" : ""}
              onClick={() => handleTabClick("internal")}
            >
              Internal
            </button>
            <button
              className={activeTab === "external" ? "active" : ""}
              onClick={() => handleTabClick("external")}
            >
              External
            </button>
            <button
              className={activeTab === "facility" ? "active" : ""}
              onClick={() => handleTabClick("facility")}
            >
              Facility
            </button>
          </div>

          {activeTab === "internal" && <Clientinternal info={info} Fetchteammember={Fetchteammember} closeModal={closeModal} />}

          {activeTab === "external" && <ClientExternal info={info} Fetchteammember={Fetchteammember} closeModal={closeModal} />}

          {activeTab === "facility" && <ClientFacility info={info} Fetchteammember={Fetchteammember} closeModal={closeModal} />}

        </div>

      </>}


      <div className="overlay" id="overlay" onClick={closeModal}></div>
    </div>
  );
};

export default CareTeam;
